import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Text,
  VStack,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { Select } from "chakra-react-select";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NavButton from "../../../component/NavButton/Index";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import { baseURL, apiKey } from "../../../utils/appURL";
import Logo from "../../../assets/images/logo.png";
import { formattedCurrency } from "../../../utils/formatedCurrency";
import formatedDate from "../../../utils/formatedDate";
import { FONTSIZES } from "../../../theme/FontSize";
import MyIcon from "../../../component/MyIcon.js/Index";
import ActivityLogs from "../../../component/ActivityLog/Index";
import MyLoader from "../../../component/loader/MyLoader";
const DetailSale = () => {
  const navigate = useNavigate();
  const productId = useParams();
  const [data, setdata] = useState({
    name: "",
    category: "",
    code: "",
    total: 0,
    store: {
      name: "",
      code: "",
      address: "",
    },
    sales_details: [],
    activity_logs: [],
    payment_method: { name: "" },
    transaction_date: "",
  });
  const [dataProduct, setdataProduct] = useState([]);
  const [dataPayment, setdataPayment] = useState([]);
  const [selectedCategory, setselectedCategory] = useState();
  const [dataCategory, setdataCategory] = useState([]);
  const [selectedSubCategory, setselectedSubCategory] = useState();
  const [isActive, setisActive] = useState();
  const [isPoint, setisPoint] = useState();
  const [subCategory, setsubCategory] = useState([]);
  const [units, setunits] = useState([]);
  const [selectedUnitCode, setselectedUnitCode] = useState();
  const [smallUnits, setsmallUnits] = useState([]);
  const [selectedSmallUnit, setselectedSmallUnit] = useState();
  const token = localStorage.getItem("token");
  const [isDisable, setisDisable] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setisLoading] = useState(true);
  const toast = useToast();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = () => {
    axios
      .get(`${baseURL}/transactions/sales/${productId.id}`, {
        headers: {
          apikey: apiKey,
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setdata(res.data.data);
        setdataProduct(res.data.data.sales_details);
        setdataPayment(res.data.data.payment_details);
        setisActive(res.data.data.is_active);
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  const clickEdit = () => {
    setisDisable(!isDisable);
  };

  const handleActive = (e) => {
    setisActive(!isActive);
    setdata((prevData) => ({
      ...prevData,
      isActive: e.target.value,
    }));
  };
  const handleEdit = () => {
    axios
      .put(
        `${baseURL}/transactions/purchases/update/${productId.id}`,
        {
          name: data.name,
          code: data.code,
          address: data.address,
          is_active: isActive,
        },
        {
          headers: {
            apikey: apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setisDisable(true);
      })
      .catch((err) => {});
  };
  const handleDelete = () => {
    axios
      .delete(
        `${baseURL}/master/stores/delete/${productId.id}`,

        {
          headers: {
            apikey: apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        onClose();
        navigate(-1);
        toast({
          title: "Success",
          description: res.data.message,
          status: "success",
          duration: 1000,
          position: "top",
        });
        setisDisable(true);
      })
      .catch((err) => {
        const status = err.response?.status;
        if (status == 401) {
          navigate("/");
        }
        toast({
          title: err.response?.data.message,
          description: err.response.data.message,
          status: "error",
          duration: 1000,
          position: "top",
        });
      });
  };
  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"} color="black">
              Sales {data.transaction_no}
            </Text>
          </Box>
          <Button colorScheme="teal" onClick={onOpen}>
            <HStack>
              <MyIcon name={"notepad-text"} size={14} />
              <Text
                fontSize={FONTSIZES.md}
                fontFamily={"SemiBold"}
                color="white"
              >
                Show Log
              </Text>
            </HStack>
          </Button>
        </HStack>
        {isLoading ? (
          <MyLoader />
        ) : (
          <Box borderWidth={1} borderRadius={5} p={5}>
            <HStack spacing={10} flex={1} alignItems={"flex-start"}>
              <Stack flex={3} py={4} spacing={5}>
                <HStack>
                  <HStack flex={1} spacing={2} alignItems={"center"}>
                    <Box flex={0.1}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Store
                      </Text>
                    </Box>
                    <Box flex={0.8}>
                      <Input
                        fontSize={FONTSIZES.md}
                        readOnly
                        fontFamily={"SemiBold"}
                        value={data.store.name}
                      />
                    </Box>
                  </HStack>
                </HStack>
                <HStack flex={1} spacing={2} alignItems={"center"}>
                  <Box flex={0.1}>
                    <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                      Code
                    </Text>
                  </Box>
                  <Box flex={0.8}>
                    <Input
                      fontSize={FONTSIZES.md}
                      readOnly
                      fontFamily={"SemiBold"}
                      defaultValue={data.store.code}
                    />
                  </Box>
                </HStack>
                <HStack flex={1} spacing={2} alignItems={"center"}>
                  <Box flex={0.1}>
                    <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                      Address
                    </Text>
                  </Box>
                  <Box flex={0.8}>
                    <Input
                      fontSize={FONTSIZES.md}
                      readOnly
                      fontFamily={"SemiBold"}
                      defaultValue={data.store.address}
                    />
                  </Box>
                </HStack>
              </Stack>
              <Stack flex={3} py={4} spacing={5}>
                <HStack>
                  <HStack flex={1} spacing={2} alignItems={"center"}>
                    <Box flex={0.1}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Date
                      </Text>
                    </Box>
                    <Box flex={0.8}>
                      <Input
                        fontSize={FONTSIZES.md}
                        readOnly
                        fontFamily={"SemiBold"}
                        value={formatedDate(data.transaction_date)}
                      />
                    </Box>
                  </HStack>
                </HStack>
                <HStack>
                  <HStack flex={1} spacing={2} alignItems={"center"}>
                    <Box flex={0.1}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Member
                      </Text>
                    </Box>
                    <Box flex={0.8}>
                      <Input
                        fontSize={FONTSIZES.md}
                        readOnly
                        fontFamily={"SemiBold"}
                        value={data.person ? data.person.name : "-"}
                      />
                    </Box>
                  </HStack>
                </HStack>
                <HStack>
                  <HStack flex={1} spacing={2} alignItems={"center"}>
                    <Box flex={0.1}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Total Item
                      </Text>
                    </Box>
                    <Box flex={0.8}>
                      <Input
                        fontSize={FONTSIZES.md}
                        readOnly
                        fontFamily={"SemiBold"}
                        value={data.sales_details?.length}
                      />
                    </Box>
                  </HStack>
                </HStack>
                <HStack>
                  <HStack flex={1} spacing={2} alignItems={"center"}>
                    <Box flex={0.1}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Payment Method
                      </Text>
                    </Box>
                    <Box flex={0.8}>
                      <Input
                        fontSize={FONTSIZES.md}
                        readOnly
                        fontFamily={"SemiBold"}
                        value={data.payment_method?.name}
                      />
                    </Box>
                  </HStack>
                </HStack>
              </Stack>
            </HStack>
          </Box>
        )}
      </Box>
      {/* Detail */}
      {isLoading ? (
        <MyLoader />
      ) : (
        <Box bg="white" p={5} borderRadius={5} mt={5}>
          <HStack justifyContent={"space-between"} mb={5}>
            <Box>
              <Text
                fontSize={FONTSIZES.md}
                fontFamily={"SemiBold"}
                color="black"
              >
                Detail Sales
              </Text>
            </Box>
          </HStack>
          <Box bg="teal.500" borderTopRadius={5} p={5}>
            <HStack flex={1}>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Product Code
                  </Text>
                </Box>
                <Box flex={0.2}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Product
                  </Text>
                </Box>
                <Box flex={0.1}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Unit
                  </Text>
                </Box>
                <Box flex={0.2}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Price
                  </Text>
                </Box>
                <Box flex={0.1}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Quantity
                  </Text>
                </Box>
                <Box flex={0.2}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Total
                  </Text>
                </Box>
                <Box flex={0.2}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Discount
                  </Text>
                </Box>
                <Box flex={0.2}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Sub Total
                  </Text>
                </Box>
              </HStack>
            </HStack>
          </Box>
          <Stack borderWidth={1} borderBottomRadius={5}>
            {dataProduct.map((item, index) => {
              return (
                <HStack p="5" borderBottomWidth={1} flex={1} key={index}>
                  <HStack flex={1} spacing={10} alignItems={"center"}>
                    <Box flex={0.2}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        {item.product.code}
                      </Text>
                    </Box>
                    <Box flex={0.2}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        {item.product.name}
                      </Text>
                    </Box>
                    <Box flex={0.1}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        {item.product.unit.name}
                      </Text>
                    </Box>
                    <Box flex={0.2}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Rp {formattedCurrency(item.price)}
                      </Text>
                    </Box>
                    <Box flex={0.1}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        {item.quantity}
                      </Text>
                    </Box>
                    <Box flex={0.2}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Rp {formattedCurrency(item.total)}
                      </Text>
                    </Box>
                    <Box flex={0.2}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Rp {formattedCurrency(item.discount)}
                      </Text>
                    </Box>
                    <Box flex={0.2}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Rp {formattedCurrency(item.subtotal)}
                      </Text>
                    </Box>
                  </HStack>
                </HStack>
              );
            })}

            <Stack>
              <HStack px="5" flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}></Box>
                <Box flex={0.2}></Box>
                <Box flex={0.1}></Box>
                <Box flex={0.2}></Box>
                <Box flex={0.1}></Box>
                <Box flex={0.2}></Box>
                <Box flex={0.2}>
                  <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
                    Sub Total
                  </Text>
                </Box>
                <Box flex={0.2}>
                  <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
                    Rp {formattedCurrency(data.grand_total)}
                  </Text>
                </Box>
              </HStack>
              <HStack px="5" flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}></Box>
                <Box flex={0.2}></Box>
                <Box flex={0.1}></Box>
                <Box flex={0.2}></Box>
                <Box flex={0.1}></Box>
                <Box flex={0.2}></Box>
                <Box flex={0.2}>
                  <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
                    Delivery Fee
                  </Text>
                </Box>
                <Box flex={0.2}>
                  <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
                    Rp {formattedCurrency(data.delivery_fee)}
                  </Text>
                </Box>
              </HStack>
              <HStack px="5" flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}></Box>
                <Box flex={0.2}></Box>
                <Box flex={0.1}></Box>
                <Box flex={0.2}></Box>
                <Box flex={0.1}></Box>
                <Box flex={0.2}></Box>
                <Box flex={0.2}>
                  <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
                    Tax
                  </Text>
                </Box>
                <Box flex={0.2}>
                  <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
                    Rp {formattedCurrency(data.tax)}
                  </Text>
                </Box>
              </HStack>

              <HStack px="5" flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}></Box>
                <Box flex={0.2}></Box>
                <Box flex={0.1}></Box>
                <Box flex={0.2}></Box>
                <Box flex={0.1}></Box>
                <Box flex={0.2}></Box>
                <Box flex={0.2}>
                  <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
                    Voucher
                  </Text>
                </Box>
                <Box flex={0.2}>
                  <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
                    Rp {formattedCurrency(data.voucher)} ({data.voucher_code})
                  </Text>
                </Box>
              </HStack>

              <HStack
                py="2"
                px="5"
                borderTopWidth={1}
                flex={1}
                spacing={10}
                alignItems={"center"}
              >
                <Box flex={0.2}></Box>
                <Box flex={0.2}></Box>
                <Box flex={0.1}></Box>
                <Box flex={0.2}></Box>
                <Box flex={0.1}></Box>
                <Box flex={0.2}></Box>
                <Box flex={0.2}>
                  <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
                    Grand Total
                  </Text>
                </Box>
                <Box flex={0.2}>
                  <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
                    Rp {formattedCurrency(data.grand_total)}
                  </Text>
                </Box>
              </HStack>
            </Stack>
          </Stack>
        </Box>
      )}
      {/* Detail Payment */}
      {data.payment_method.name === "PAYLATER" && (
        <Box bg="white" p={5} borderRadius={5} mt={5}>
          <HStack justifyContent={"space-between"} mb={5}>
            <Box>
              <Text
                fontSize={FONTSIZES.md}
                fontFamily={"SemiBold"}
                color="black"
              >
                Detail Payment
              </Text>
            </Box>
          </HStack>
          <Box bg="teal.500" borderTopRadius={5} p={5}>
            <HStack flex={1}>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Payment No
                  </Text>
                </Box>
                <Box flex={0.2}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Payment Date
                  </Text>
                </Box>
                <Box flex={0.2}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Notes
                  </Text>
                </Box>
                <Box flex={0.2}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Total Bill
                  </Text>
                </Box>
                <Box flex={0.2}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Total Payment
                  </Text>
                </Box>
                <Box flex={0.2}>
                  <Text
                    fontSize={FONTSIZES.md}
                    color={"white"}
                    fontFamily={"SemiBold"}
                  >
                    Remaining
                  </Text>
                </Box>
              </HStack>
            </HStack>
          </Box>
          <Stack borderWidth={1} borderBottomRadius={5}>
            {dataPayment.map((item, index) => {
              return (
                <HStack p="5" borderBottomWidth={1} flex={1} key={index}>
                  <HStack flex={1} spacing={10} alignItems={"center"}>
                    <Box flex={0.2}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        {item.payment_header.transaction_no}
                      </Text>
                    </Box>
                    <Box flex={0.2}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        {formatedDate(item.payment_header.payment_date)}
                      </Text>
                    </Box>
                    <Box flex={0.2}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        {item.notes}
                      </Text>
                    </Box>
                    <Box flex={0.2}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Rp. {formattedCurrency(item.bill)}
                      </Text>
                    </Box>
                    <Box flex={0.2}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Rp {formattedCurrency(item.pay)}
                      </Text>
                    </Box>
                    <Box flex={0.2}>
                      <Text fontSize={FONTSIZES.md} fontFamily={"Regular"}>
                        Rp {formattedCurrency(item.remaining)}
                      </Text>
                    </Box>
                  </HStack>
                </HStack>
              );
            })}
          </Stack>
        </Box>
      )}
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Data log sales : {data.transaction_no}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {data.activity_logs.map((item, index) => {
              return <ActivityLogs item={item} key={index} index={index} />;
            })}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </AnimatedContainer>
  );
};
export default DetailSale;
