const iconNameFormatter = (str) => {
  // Memisahkan string menjadi array berdasarkan tanda hubung
  const words = str.split("-");

  // Mengonversi setiap kata pertama menjadi huruf besar
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Menggabungkan kata-kata menjadi satu string tanpa spasi di antara
  return capitalizedWords.join("");
};
export default iconNameFormatter;
