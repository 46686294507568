import {
  Box,
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActivityLogs from "../../../component/ActivityLog/Index";
import MyIcon from "../../../component/MyIcon.js/Index";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import MyLoader from "../../../component/loader/MyLoader";
import { FONTSIZES } from "../../../theme/FontSize";
import { URL } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";
const DetailMenu = () => {
  const { get, put, deleting } = useHttp();
  const productId = useParams();
  const [data, setdata] = useState({
    title: "",
    parent_menu_id: null,
    url: "",
    module: "",
    position: 0,
    activity_logs: [],
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedParent, setselectedParent] = useState({ id: "", title: "" });
  const [dataMenu, setdataMenu] = useState([]);
  const [isParent, setisParent] = useState(true);
  const [dataPermission, setdataPermission] = useState([]);
  const [selectedPermission, setselectedPermission] = useState({
    id: "",
    title: "",
  });
  const [showLog, setshowLog] = useState(false);

  const [isActive, setisActive] = useState(false);

  const [isDisable, setisDisable] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const navigate = useNavigate();

  const [isLoading, setisLoading] = useState(true);
  const [isError, setisError] = useState({
    title: "Required",
    module: "Required",

    position: "Required",
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = () => {
    const params = {
      title: data.title,
      is_parent: isParent,
      parent_menu_id: !isParent ? selectedParent.id : null,
      url: data.url,
      module: data.module,
      position: data.position,
      is_active: true,
      permission: selectedPermission.id,
    };
    put({
      url: URL.getListMasterMenu + `/update/${productId.id}`,
      needAuth: true,
      showToast: true,
      data: params,
    }).then((res) => {
      navigate(-1);
    });
  };

  const handleChange = (props) => {
    const { name, value } = props.target;

    if (name === "url") {
      setdata((prevState) => ({
        ...prevState,
        url: value,
      }));
    } else if (name === "title") {
      setdata((prevState) => ({
        ...prevState,
        title: value,
      }));
      if (value === "") {
        setisError((prevState) => ({
          ...prevState,
          name: "Required",
        }));
      } else {
        setisError((prevState) => ({
          ...prevState,
          name: "Minimum 4 character",
        }));
      }
    } else if (name === "module") {
      setdata((prevState) => ({
        ...prevState,
        module: value,
      }));
      if (value === "") {
        setisError((prevState) => ({
          ...prevState,
          module: "Required",
        }));
      } else {
        setisError((prevState) => ({
          ...prevState,
          module: "Minimum 4 character",
        }));
      }
    } else if (name === "position") {
      setdata((prevState) => ({
        ...prevState,
        position: value,
      }));
      if (value === "") {
        setisError((prevState) => ({
          ...prevState,
          position: "Required",
        }));
      } else {
        setisError((prevState) => ({
          ...prevState,
          position: "Minimum 4 character",
        }));
      }
    }
  };

  const clickEdit = () => {
    setisDisable(!isDisable);
  };
  const handleDelete = () => {
    deleting({
      url: URL.getListMasterMenu + `/delete/${productId.id}`,
      needAuth: true,
      showToast: true,
    }).then((res) => {
      onClose();
      navigate(-1);
    });
  };

  const fetchData = () => {
    get({
      url: URL.getListMasterMenu + `/${productId.id}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setselectedPermission(
        res.data.data.menu_permission
          ? res.data.data.menu_permission.permission
          : { id: "", title: "" }
      );
      setselectedParent(
        res.data.data.parent_menu
          ? res.data.data.parent_menu
          : { id: "", title: "" }
      );
      setisParent(res.data.data.is_parent === 1 ? true : false);
      setdata(res.data.data);
      setisActive(res.data.data.is_active === 1 ? true : false);
      setisLoading(false);
    });
    get({
      url: URL.getListMasterMenu + `?page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setdataMenu(res.data.data.data);
    });

    get({
      url: URL.getListPermissionURL,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setdataPermission(res.data.data);
    });
  };
  useEffect(() => {
    fetchData();
  }, [currentPage]);

  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Menu
            </Text>
          </Box>
          <HStack>
            <Button colorScheme="teal" onClick={() => setshowLog(true)}>
              <HStack>
                <MyIcon name={"notepad-text"} size={14} />
                <Text
                  fontSize={FONTSIZES.md}
                  fontFamily={"SemiBold"}
                  color="white"
                >
                  Show Log
                </Text>
              </HStack>
            </Button>
            <Button
              colorScheme="blue"
              minW={"100px"}
              bg={isDisable ? "blue.500" : "gray.500"}
              onClick={clickEdit}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={FONTSIZES.md}
                >
                  {isDisable ? "Edit" : "Cancel"}
                </Text>
              </HStack>
            </Button>
            <Button
              colorScheme="red"
              minW={"100px"}
              bg={"red.500"}
              onClick={onOpen}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={FONTSIZES.md}
                >
                  Delete
                </Text>
              </HStack>
            </Button>
            {/* <NavButton nav={-1} title="Back" /> */}
          </HStack>
        </HStack>
        {isLoading ? (
          <MyLoader />
        ) : (
          <Box borderWidth={1} borderRadius={5} p={5}>
            <Text fontFamily={"Semibold"}>Detail Menu</Text>
            <HStack flex={1}>
              <Stack flex={3} py={4} spacing={5}>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Type</Text>
                  </Box>
                  <Stack flex={0.7}>
                    <HStack>
                      <Button
                        isDisabled={isDisable}
                        bg={isParent ? "teal.500" : "gray.500"}
                        colorScheme="teal"
                        px="10"
                        onClick={() => setisParent(true)}
                      >
                        <Text
                          fontFamily={"Medium"}
                          color="white"
                          fontSize={[10, 12, 14]}
                        >
                          Main Menu
                        </Text>
                      </Button>
                      <Button
                        isDisabled={isDisable}
                        bg={!isParent ? "teal.500" : "gray.500"}
                        onClick={() => setisParent(false)}
                        colorScheme="teal"
                        px="10"
                      >
                        <Text
                          fontFamily={"Medium"}
                          color="white"
                          fontSize={[10, 12, 14]}
                        >
                          Sub Menu
                        </Text>
                      </Button>
                    </HStack>
                  </Stack>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Title</Text>
                  </Box>
                  <Stack flex={0.7}>
                    <Input
                      value={data.title}
                      name="title"
                      isInvalid={data.title.length < 4}
                      readOnly={isDisable}
                      fontFamily={"Regular"}
                      onChange={(e) => handleChange(e)}
                    />
                    {data.title.length < 4 && (
                      <AnimatedContainer>
                        <Text
                          color="red.500"
                          fontSize={"xs"}
                          fontFamily={"Medium"}
                        >
                          {isError.title}
                        </Text>
                      </AnimatedContainer>
                    )}
                  </Stack>
                </HStack>
                {!isParent && (
                  <HStack flex={1} spacing={10} alignItems={"center"}>
                    <Box flex={0.2}>
                      <Text>Parent Menu</Text>
                    </Box>
                    <Box flex={0.7}>
                      <Select
                        isReadOnly={isDisable}
                        value={{
                          value: selectedParent.id,
                          label: selectedParent.title,
                        }}
                        onChange={(e) =>
                          setselectedParent({ id: e.value, title: e.label })
                        }
                        w={500}
                        tagVariant="solid"
                        options={dataMenu?.map((item) => ({
                          value: item.id,
                          label: item.title,
                        }))}
                      />
                    </Box>
                  </HStack>
                )}
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Position</Text>
                  </Box>
                  <Stack flex={0.7}>
                    <Input
                      value={data.position}
                      type="number"
                      name="position"
                      isInvalid={data.position === 0}
                      readOnly={isDisable}
                      fontFamily={"Regular"}
                      onChange={(e) => handleChange(e)}
                    />
                    {data.position === 0 && (
                      <AnimatedContainer>
                        <Text
                          color="red.500"
                          fontSize={"xs"}
                          fontFamily={"Medium"}
                        >
                          {isError.position}
                        </Text>
                      </AnimatedContainer>
                    )}
                  </Stack>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Url</Text>
                  </Box>
                  <Stack flex={0.7}>
                    <Input
                      value={data.url}
                      name="url"
                      readOnly={isDisable}
                      fontFamily={"Regular"}
                      onChange={(e) => handleChange(e)}
                    />
                  </Stack>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Module</Text>
                  </Box>
                  <Stack flex={0.7}>
                    <Input
                      value={data.module}
                      name="module"
                      readOnly={isDisable}
                      fontFamily={"Regular"}
                      onChange={(e) => handleChange(e)}
                    />
                  </Stack>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Permission</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Select
                      isReadOnly={isDisable}
                      value={{
                        value: selectedPermission.id,
                        label: selectedPermission.name,
                      }}
                      onChange={(e) =>
                        setselectedPermission({ id: e.value, name: e.label })
                      }
                      w={500}
                      tagVariant="solid"
                      options={dataPermission?.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                  </Box>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Active</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Switch
                      readOnly={isDisable}
                      onChange={() => setisActive(!isActive)}
                      isChecked={isActive == 1 ? true : false}
                    />
                  </Box>
                </HStack>
              </Stack>
            </HStack>
          </Box>
        )}
        {isDisable !== true && (
          <HStack py={10} justifyContent={"flex-end"}>
            <Button
              isDisabled={selectedParent === null}
              onClick={handleSubmit}
              colorScheme="teal"
              minW={"100px"}
              bg={"teal.500"}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={FONTSIZES.md}
                >
                  Save
                </Text>
              </HStack>
            </Button>
          </HStack>
        )}
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Delete Menu {data.title}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Are you sure?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant={"ghost"} mr={3} onClick={onClose}>
              <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                Close
              </Text>
            </Button>
            <Button colorScheme="red" onClick={handleDelete}>
              <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                Delete
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={showLog}
        onClose={() => setshowLog(false)}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Data log menu : {data.title}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {data.activity_logs?.map((item) => {
              return <ActivityLogs item={item} />;
            })}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </AnimatedContainer>
  );
};
export default DetailMenu;
