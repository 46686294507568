const dev = "http://192.168.1.88:8093//v1";
const prod = "https://api-gomo.goldenmom-service.com/v1";
const devImage = "http://192.168.1.88:8093//uploads";
const prodImage = "https://api-gomo.goldenmom-service.com/uploads";
const devApiKey = "x2rNqvJ8UkAYpsoM3Yd000O82DRdPJPE";
const prodApiKey = "gomoxgmi2024";

export const isProduction = true;
export const baseURL = isProduction === true ? prod : dev;
export const baseURLImage = isProduction === true ? prodImage : devImage;
export const apiKey = isProduction === true ? prodApiKey : devApiKey;

export const URL = {
  loginURL: `${baseURL}/auth/panel/login`,
  logoutURL: `${baseURL}/auth/logout`,
  getDataSessionUserURL: `${baseURL}/auth/me`,
  getListUserURL: `${baseURL}/settings/users`,
  getListMitraURL: `${baseURL}/master/mitra`,
  getListCashierURL: `${baseURL}/master/cashier`,
  getListPaymentMethod: `${baseURL}/master/payment-method`,
  getListRoleURL: `${baseURL}/settings/roles`,
  getListPermissionURL: `${baseURL}/settings/permissions`,
  getListMasterMenu: `${baseURL}/settings/menus`,
  getListMenuURL: `${baseURL}/auth/menu`,
  getDashboardDataURL: `${baseURL}/dashboard`,
  getListProductURL: `${baseURL}/master/products`,
  getListProductCategoryURL: `${baseURL}/master/product-categories`,
  getListProductSubCategoryURL: `${baseURL}/master/product-subcategories`,
  getListProductUnitURL: `${baseURL}/master/product-units`,
  getListCustomerURL: `${baseURL}/master/customers`,
  getListStoreURL: `${baseURL}/master/stores`,
  getPriceListURL: `${baseURL}/master/products/price-lists`,
  getMovementStockURL: `${baseURL}/master/products/movement-stocks`,
  getInventoryURL: `${baseURL}/master/products/inventory`,
  getListOtherTypeURL: `${baseURL}/master/other-type`,
  // Transaction
  getListSalesURL: `${baseURL}/transactions/sales`,
  getListPurchaseURL: `${baseURL}/transactions/purchases`,
  getListOtherURL: `${baseURL}/transactions/others`,
  getListVoucherURL: `${baseURL}/transactions/vouchers`,
  getPromotionURL: `${baseURL}/transactions/promotions`,
  getPaymentBillURL: `${baseURL}/transactions/payments`,
  getListReturPurchaseURL: `${baseURL}/transactions/retur-purchases`,
  // Report
  getReportSalesURL: `${baseURL}/report/sales`,
  getReportPurchaseURL: `${baseURL}/report/purchase`,
  getReportOtherURL: `${baseURL}/report/others`,
  getReportPromotionURL: `${baseURL}/report/promotions`,
  getReportReturPurchaseURL: `${baseURL}/report/retur-purchase`,
  getReportPaymentBillURL: `${baseURL}/report/payments`,
};
