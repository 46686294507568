import {
  Box,
  Button,
  HStack,
  Input,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import { FONTSIZES } from "../../../theme/FontSize";
import { URL } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";
const AddPayment = () => {
  const { post } = useHttp();

  const [data, setdata] = useState({ name: "", Payment: "", code: "" });

  const [isActive, setisActive] = useState();

  const [isDisable, setisDisable] = useState(false);
  const navigate = useNavigate();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = () => {
    const dataSubmit = {
      name: data.name,
      is_active: isActive,
    };
    post({
      url: URL.getListPaymentMethod + `/store`,
      needAuth: true,
      showToast: true,
      data: dataSubmit,
    }).then((res) => {
      navigate(-1);
    });
  };

  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Payment
            </Text>
          </Box>
        </HStack>
        <Box borderWidth={1} borderRadius={5} p={5}>
          <Text fontFamily={"Semibold"}>Detail Payment</Text>
          <HStack flex={1}>
            <Stack flex={3} py={4} spacing={5}>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Name</Text>
                </Box>
                <Box flex={0.7}>
                  <Input
                    readOnly={isDisable}
                    fontFamily={"Regular"}
                    onChange={(e) =>
                      setdata((prevData) => ({
                        ...prevData,
                        name: e.target.value,
                      }))
                    }
                  />
                </Box>
              </HStack>

              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Active</Text>
                </Box>
                <Box flex={0.7}>
                  <Switch
                    readOnly={isDisable}
                    onChange={() => setisActive(!isActive)}
                    isChecked={isActive == 1 ? true : false}
                  />
                </Box>
              </HStack>
            </Stack>
          </HStack>
        </Box>

        {isDisable !== true && (
          <HStack py={10} justifyContent={"flex-end"}>
            <Button
              onClick={handleSubmit}
              colorScheme="teal"
              minW={"100px"}
              bg={"teal.500"}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={FONTSIZES.md}
                >
                  Save
                </Text>
              </HStack>
            </Button>
          </HStack>
        )}
      </Box>
    </AnimatedContainer>
  );
};
export default AddPayment;
