import { createSlice } from "@reduxjs/toolkit";

const userDataSlice = createSlice({
  name: "userData",
  initialState: {
    value: {},
  },
  reducers: {
    setData: (state, data) => {
        const item = data.payload;
        state.value = item;
    },
    clearData: (state) => {
        state.value = {};
    },
  },
});

export const { setData, clearData } = userDataSlice.actions;

export default userDataSlice.reducer;
