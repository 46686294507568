import {
  Box,
  Button,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActivityLogs from "../../../component/ActivityLog/Index";
import MyIcon from "../../../component/MyIcon.js/Index";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import MyLoader from "../../../component/loader/MyLoader";
import { FONTSIZES } from "../../../theme/FontSize";
import { URL } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";
const DetailUser = () => {
  const { get, put, deleting } = useHttp();
  const productId = useParams();
  const [data, setdata] = useState({
    name: "",
    username: "",
    password: "",
    activity_logs: [],
  });
  const [selectedRole, setselectedRole] = useState(null);
  const [dataRole, setdataRole] = useState([]);
  const [isActive, setisActive] = useState(false);
  const token = localStorage.getItem("token");
  const [isDisable, setisDisable] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);
  const navigate = useNavigate();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [showLog, setshowLog] = useState(false);
  const toast = useToast();
  const [isLoading, setisLoading] = useState(true);
  const [isError, setisError] = useState({
    name: "Required",
    username: "Required",
    password: "Required",
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = () => {
    let params = {
      name: data.name,
      role: selectedRole,
      username: data.username,
      is_active: isActive,
      address: data.address,
    };
    if (data.password.length > 0) {
      params = {
        name: data.name,
        role: selectedRole,
        username: data.username,
        password: data.password,
        is_active: isActive,
        address: data.address,
      };
    }
    put({
      url: URL.getListUserURL + `/update/${productId.id}`,
      needAuth: true,
      showToast: true,
      data: params,
    }).then((res) => {
      navigate(-1);
    });
  };

  const fetchData = () => {
    get({
      url: URL.getListUserURL + `/${productId.id}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      const dataRes = res.data.data;

      setdata((prevState) => ({
        ...prevState,
        address: dataRes.address,
        name: dataRes.name,
        username: dataRes.person_access.username,
        activity_logs: dataRes.activity_logs,
      }));
      setselectedRole(res.data.data.person_access.roles[0].name);

      setisActive(dataRes.is_active);
      setisLoading(false);
    });
    get({
      url: URL.getListRoleURL,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setdataRole(res.data.data.data);
    });
  };
  const handleChange = (props) => {
    const { name, value } = props.target;
    if (name === "password") {
      setdata((prevState) => ({
        ...prevState,
        password: value,
      }));
      if (value === "") {
        setisError((prevState) => ({
          ...prevState,
          password: "Required",
        }));
      } else {
        setisError((prevState) => ({
          ...prevState,
          password: "Minimum 8 character",
        }));
      }
    } else if (name === "name") {
      setdata((prevState) => ({
        ...prevState,
        name: value,
      }));
      if (value === "") {
        setisError((prevState) => ({
          ...prevState,
          name: "Required",
        }));
      } else {
        setisError((prevState) => ({
          ...prevState,
          name: "Must be more than 4 character",
        }));
      }
    } else if (name === "address") {
      setdata((prevState) => ({
        ...prevState,
        address: value,
      }));
    } else if (name === "username") {
      setdata((prevState) => ({
        ...prevState,
        username: value,
      }));
      if (value === "") {
        setisError((prevState) => ({
          ...prevState,
          username: "Required",
        }));
      } else {
        setisError((prevState) => ({
          ...prevState,
          username: "Must be more than 4 character",
        }));
      }
    }
  };
  const clickEdit = () => {
    setisDisable(!isDisable);
  };
  const handleDelete = () => {
    deleting({
      url: URL.getListUserURL + `/delete/${productId.id}`,
      needAuth: true,
      showToast: true,
    }).then((res) => {
      onClose();
      navigate(-1);
    });
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              User
            </Text>
          </Box>
          <HStack>
            <Button colorScheme="teal" onClick={() => setshowLog(true)}>
              <HStack>
                <MyIcon name={"notepad-text"} size={14} />
                <Text
                  fontSize={FONTSIZES.md}
                  fontFamily={"SemiBold"}
                  color="white"
                >
                  Show Log
                </Text>
              </HStack>
            </Button>
            <Button
              colorScheme="blue"
              minW={"100px"}
              bg={isDisable ? "blue.500" : "gray.500"}
              onClick={clickEdit}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={FONTSIZES.md}
                >
                  {isDisable ? "Edit" : "Cancel"}
                </Text>
              </HStack>
            </Button>
            <Button
              colorScheme="red"
              minW={"100px"}
              bg={"red.500"}
              onClick={onOpen}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={FONTSIZES.md}
                >
                  Delete
                </Text>
              </HStack>
            </Button>
            {/* <NavButton nav={-1} title="Back" /> */}
          </HStack>
        </HStack>
        {isLoading ? (
          <MyLoader />
        ) : (
          <Box borderWidth={1} borderRadius={5} p={5}>
            <Text fontFamily={"Semibold"}>Detail User</Text>
            <HStack flex={1}>
              <Stack flex={3} py={4} spacing={5}>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Name</Text>
                  </Box>
                  <Stack flex={0.7}>
                    <Input
                      value={data.name}
                      name="name"
                      isInvalid={data.name.length < 4}
                      isDisabled={isDisable}
                      fontFamily={"Regular"}
                      onChange={(e) => handleChange(e)}
                    />
                    {data.name.length < 4 && (
                      <AnimatedContainer>
                        <Text
                          color="red.500"
                          fontSize={"xs"}
                          fontFamily={"Medium"}
                        >
                          {isError.name}
                        </Text>
                      </AnimatedContainer>
                    )}
                  </Stack>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Address</Text>
                  </Box>
                  <Stack flex={0.7}>
                    <Input
                      value={data.address}
                      name="address"
                      isDisabled={isDisable}
                      fontFamily={"Regular"}
                      onChange={(e) => handleChange(e)}
                    />
                  </Stack>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Role</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Select
                      isDisabled={isDisable}
                      value={{
                        value: selectedRole,
                        label: selectedRole,
                      }}
                      onChange={(e) => setselectedRole(e.value)}
                      w={500}
                      tagVariant="solid"
                      options={dataRole.map((item) => ({
                        value: item.name,
                        label: item.name,
                      }))}
                    />
                  </Box>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Username</Text>
                  </Box>
                  <Stack flex={0.7}>
                    <Input
                      value={data.username}
                      name="username"
                      isInvalid={data.username.length < 4}
                      isDisabled={isDisable}
                      fontFamily={"Regular"}
                      onChange={(e) => handleChange(e)}
                    />
                    {data.username.length < 4 && (
                      <AnimatedContainer>
                        <Text
                          color="red.500"
                          fontSize={"xs"}
                          fontFamily={"Medium"}
                        >
                          {isError.username}
                        </Text>
                      </AnimatedContainer>
                    )}
                  </Stack>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Password</Text>
                  </Box>
                  <Stack flex={0.7}>
                    <Input
                      name="password"
                      isInvalid={
                        data.password.length > 0 && data.password.length < 8
                      }
                      isDisabled={isDisable}
                      fontFamily={"Regular"}
                      onChange={(e) => handleChange(e)}
                    />
                    {data.password.length > 0 && data.password.length < 8 && (
                      <AnimatedContainer>
                        <Text
                          color="red.500"
                          fontSize={"xs"}
                          fontFamily={"Medium"}
                        >
                          {isError.password}
                        </Text>
                      </AnimatedContainer>
                    )}
                  </Stack>
                </HStack>

                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Active</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Switch
                      isDisabled={isDisable}
                      onChange={() => setisActive(!isActive)}
                      isChecked={isActive == 1 ? true : false}
                    />
                  </Box>
                </HStack>
              </Stack>
            </HStack>
          </Box>
        )}
        {isDisable !== true && (
          <HStack py={10} justifyContent={"flex-end"}>
            <Button
              isDisabled={
                data.username.length < 4 ||
                (data.password.length < 8 && data.password.length > 0) ||
                data.name.length < 4 ||
                selectedRole === null
              }
              onClick={handleSubmit}
              colorScheme="teal"
              minW={"100px"}
              bg={"teal.500"}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={FONTSIZES.md}
                >
                  Save
                </Text>
              </HStack>
            </Button>
          </HStack>
        )}
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Delete User {data.name}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Are you sure?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant={"ghost"} mr={3} onClick={onClose}>
              <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                Close
              </Text>
            </Button>
            <Button colorScheme="red" onClick={handleDelete}>
              <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
                Delete
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={showLog}
        onClose={() => setshowLog(false)}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Data log user : {data.transaction_no}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {data?.activity_logs.map((item, index) => {
              return <ActivityLogs item={item} index={index} />;
            })}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </AnimatedContainer>
  );
};
export default DetailUser;
