import { Box, HStack, Text } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import NavButton from "../../../component/NavButton/Index";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import SaleTable from "./SaleTable";
import { baseURL } from "../../../utils/appURL";

const Sale = () => {
  const [data, setdata] = useState([{ id: "", title: "" }]);
  const token = localStorage.getItem("token");

  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={"10"}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontSize={[14, 16, 18]} fontFamily={"Semibold"} color="black">
              Sales
            </Text>
          </Box>
          {/* <NavButton nav="/sale/add" title="Add Sale" /> */}
        </HStack>

        <SaleTable />
      </Box>
    </AnimatedContainer>
  );
};

export default Sale;
