export const chakraStyles = {
  input: (provided) => ({
    ...provided,
    fontFamily: "Regular",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontFamily: "Regular",
  }),
  menuList: (provided) => ({
    ...provided,
    fontFamily: "Regular",
  }),
  singleValue: (provided) => ({
    ...provided,
    fontFamily: "Regular",
  }),
};
