import { Box, Center, HStack, Stack, Text } from "@chakra-ui/react";
import React from "react";
import { FONTSIZES } from "../../theme/FontSize";
import formatedNumber from "../../utils/formatedNumber";
import formatedDateTime from "../../utils/formatedDateTime";
import Lottie from "react-lottie";

const MovementStock = ({ data }) => {
  return data?.length > 0 ? (
    data.map((item, index) => {
      return (
        <HStack
          p="2"
          bg={index % 2 !== 0 ? "teal.50" : "grey.50"}
          flex={1}
          key={item?.id}
          alignItems={"center"}
        >
          <Box flex={1} alignItems={"center"}>
            <Text
              textAlign="left"
              fontFamily={"Regular"}
              fontSize={FONTSIZES.md}
            >
              {formatedDateTime(item?.created_at)}
            </Text>
          </Box>
          <Box flex={1}>
            <Text
              textAlign="left"
              fontFamily={"Regular"}
              fontSize={FONTSIZES.md}
            >
              {item?.store?.name}
            </Text>
          </Box>
          <Box flex={1}>
            <Text
              textAlign="left"
              fontSize={FONTSIZES.md}
              fontFamily={"Regular"}
            >
              {item?.module}
            </Text>
          </Box>
          <Box flex={1}>
            <Text
              textAlign="center"
              fontSize={FONTSIZES.md}
              fontFamily={"Regular"}
            >
              {formatedNumber(item?.beginning_stock)}
            </Text>
          </Box>
          <Box flex={1}>
            <Text
              textAlign="center"
              fontSize={FONTSIZES.md}
              fontFamily={"Regular"}
            >
              {formatedNumber(item?.movement_stock)}
            </Text>
          </Box>
          <Box flex={1}>
            <Text
              textAlign="center"
              fontSize={FONTSIZES.md}
              fontFamily={"Regular"}
            >
              {formatedNumber(item?.end_stock)}
            </Text>
          </Box>
          <Box flex={1}>
            <Text
              textAlign="left"
              fontSize={FONTSIZES.md}
              fontFamily={"Regular"}
            >
              {item?.description}
            </Text>
          </Box>
        </HStack>
      );
    })
  ) : (
    <Stack mt={5}>
      <Center>
        <Text fontFamily={"Semibold"}>Data Not Found</Text>
      </Center>
      <Lottie
        options={{
          animationData: require("../../assets/lotties/empty.json"),
        }}
        width={"20vw"}
      />
    </Stack>
  );
};

export default MovementStock;
