import { Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { FONTSIZES } from "../../theme/FontSize";
import { formattedCurrency } from "../../utils/formatedCurrency";
import moment from "moment";

const BarChart = ({ item, title }) => {
  const [datas, setdatas] = useState([]);
  const [state, setState] = React.useState({
    series: [],
    options: {
      // colors: [Primary.Blue, Primary.Orange, Primary.Teal],

      legend: {
        // position: "top",
        borderRadius: 50,
      },
      grid: {
        strokeDashArray: 2,
      },
      chart: {
        fontFamily: "Medium",
        toolbar: {
          show: true,
        },
        type: "bar",
        height: 350,
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "80%",
          borderRadius: 4, // You can adjust this value to control the radius
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 10,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [moment().format("MMMM")],
        labels: {
          show: false,
        },
      },
      yaxis: {
        labels: {
          show: true,

          formatter: (value) => {
            return formattedCurrency(value);
          },
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return "Rp " + formattedCurrency(val);
          },
        },
        fixed: {
          enabled: true,
          position: "topRight",
          offsetX: 0,
          offsetY: 0,
        },
      },
    },
  });

  useEffect(() => {
    // Function to update the key
    const updateKey = (data) => {
      return data.map((item) => ({
        ...item,
        data: item.total, // Assuming your original data has only one data point
      }));
    };

    // Updated data
    const updatedData = updateKey(item);
    setState((prevState) => ({ ...prevState, series: updatedData }));
  }, [item]); // Trigger useEffect whenever the 'item' prop changes

  return (
    <Box>
      <Text fontFamily={"Semibold"} fontSize={FONTSIZES.lg}>
        {title}
      </Text>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={350}
      />
    </Box>
  );
};

export default BarChart;
