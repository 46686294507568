import { Button, HStack, Text } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import MyIcon from "../MyIcon.js/Index";
import { FONTSIZES } from "../../theme/FontSize";

const NavButton = ({ nav, title }) => {
  const navigate = useNavigate();
  const handleNav = () => {
    navigate(nav);
  };
  return (
    <Button minW={"100px"} bg={"teal.500"} onClick={handleNav}>
      <HStack alignItems={"center"}>
        {title === "Back" ? (
          <MyIcon name={"chevron-left"} color="white" size={14} />
        ) : (
          <MyIcon name={"plus"} color="white" size={14} />
        )}
        <Text fontFamily={"Semibold"} color="white" fontSize={FONTSIZES.md}>
          {title}
        </Text>
      </HStack>
    </Button>
  );
};

export default NavButton;
