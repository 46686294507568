import { HStack, Text } from "@chakra-ui/react";
import React from "react";
import { FONTSIZES } from "../../theme/FontSize";
import Data from "../../../package.json";
import { isProduction } from "../../utils/appURL";
const Footer = () => {
  return (
    <HStack w={"100%"} alignItems={"center"} justifyContent={"center"}>
      <Text alignSelf={"center"} fontSize={FONTSIZES.md} fontFamily={"Regular"}>
        {isProduction == false ? "Staging - " : ""} {Data.version} © PT Golden
        Mom Indonesia, 2024.
      </Text>
    </HStack>
  );
};

export default Footer;
