import React from "react";
import { Box, Progress } from "@chakra-ui/react";

const MyLoader = () => {
  return (
    <Box
      w={"100vw"}
      h="50vh"
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Progress
        w="50%"
        rounded={"full"}
        size={"sm"}
        colorScheme={"teal"}
        isIndeterminate
      />
    </Box>
  );
};

export default MyLoader;
