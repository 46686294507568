import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  Stack,
  Switch,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { Select } from "chakra-react-select";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NavButton from "../../../component/NavButton/Index";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import { baseURL, apiKey } from "../../../utils/appURL";
import formatedDate from "../../../utils/formatedDate";
import MyIcon from "../../../component/MyIcon.js/Index";
import Logo from "../../../assets/images/logo.png";
const AddUnit = () => {
  const toast = useToast();
  const productId = useParams();
  const [data, setdata] = useState({ name: "", category: "", code: "" });
  const [selectedUnit, setselectedUnit] = useState();
  const [dataUnit, setdataUnit] = useState([]);
  const [selectedSubUnit, setselectedSubUnit] = useState();
  const [isActive, setisActive] = useState();
  const [isPoint, setisPoint] = useState();
  const [subUnit, setsubUnit] = useState([]);
  const [nameUnit, setnameUnit] = useState("");
  const [units, setunits] = useState([]);
  const [selectedUnitCode, setselectedUnitCode] = useState();
  const [smallUnits, setsmallUnits] = useState([]);
  const [selectedSmallUnit, setselectedSmallUnit] = useState();
  const token = localStorage.getItem("token");
  const [isDisable, setisDisable] = useState(false);
  const navigate = useNavigate();
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleUpdate = () => {
    axios
      .post(
        `${baseURL}/master/product-units/store`,
        {
          name: data.name,
          code: data.code,
          is_active: isActive,
        },
        {
          headers: {
            apikey: apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        navigate(-1);
        toast({
          title: "Success",
          description: res.data.message,
          status: "success",
          duration: 1000,
          position: "top",
        });
      })
      .catch((err) => {
        const status = err.response?.status;
        if (status == 401) {
          navigate("/");
        }
        toast({
          title: err.response?.data.message,
          description: JSON.stringify(err.response?.data.errors),
          status: "error",
          duration: 2000,
          position: "top",
        });
      });
  };
  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Unit
            </Text>
          </Box>
        </HStack>
        <Box borderWidth={1} borderRadius={5} p={5}>
          <Text fontFamily={"Semibold"}>Detail Unit</Text>
          <HStack flex={1}>
            <Stack flex={3} py={4} spacing={5}>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Name</Text>
                </Box>
                <Box flex={0.7}>
                  <Input
                    value={data.name}
                    fontFamily={"Regular"}
                    defaultValue={nameUnit}
                    onChange={(e) =>
                      setdata((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                      }))
                    }
                  />
                </Box>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Code</Text>
                </Box>
                <Box flex={0.7}>
                  <Input
                    fontFamily={"Regular"}
                    defaultValue={data.code}
                    onChange={(e) =>
                      setdata((prevState) => ({
                        ...prevState,
                        code: e.target.value,
                      }))
                    }
                  />
                </Box>
              </HStack>

              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Active</Text>
                </Box>
                <Box flex={0.7}>
                  <Switch
                    onChange={() => setisActive(!isActive)}
                    isChecked={isActive == 1 ? true : false}
                  />
                </Box>
              </HStack>
            </Stack>
          </HStack>
        </Box>

        {isDisable !== true && (
          <HStack py={10} justifyContent={"flex-end"}>
            <Button
              onClick={handleUpdate}
              colorScheme="teal"
              minW={"100px"}
              bg={"teal.500"}
            >
              <HStack alignItems={"center"}>
                <MyIcon name="save" size="14" />
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={[10, 12, 14]}
                >
                  Save
                </Text>
              </HStack>
            </Button>
          </HStack>
        )}
      </Box>
    </AnimatedContainer>
  );
};
export default AddUnit;
