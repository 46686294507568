import { icons } from "lucide-react";
import React from "react";
import iconNameFormatter from "../../utils/iconNameFormatter";

const MyIcon = ({ name, color, size }) => {
  const nameIcon = iconNameFormatter(name);
  const LucideIcon = icons[nameIcon];

  return <LucideIcon color={color} size={size} />;
};

export default MyIcon;
