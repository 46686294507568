import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Input,
  Stack,
  Text,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import * as React from "react";
import ICFilter from "../../../assets/icons/ICFilter";
// import ICSearch from "../../../assets/icons/ICSearch";
import {
  Body,
  Cell,
  Footer,
  FooterCell,
  FooterRow,
  Header,
  HeaderCell,
  HeaderRow,
  Row,
  Table,
} from "@table-library/react-table-library";
import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/chakra-ui";
import { useTheme } from "@table-library/react-table-library/theme";
import { AsyncSelect, Select } from "chakra-react-select";
import { format } from "date-fns";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Calendar } from "lucide-react";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import emptyLotie from "../../../assets/lotties/empty.json";
import MyLoader from "../../../component/loader/MyLoader";
import MyIcon from "../../../component/MyIcon.js/Index";
import { FONTSIZES } from "../../../theme/FontSize";
import { URL } from "../../../utils/appURL";
import { formattedCurrency } from "../../../utils/formatedCurrency";
import formatedDate from "../../../utils/formatedDate";
import { useHttp } from "../../../utils/http";
const ReportPurchase = ({ label }) => {
  const { get } = useHttp();
  const [dataCsv, setdataCsv] = useState();
  const [data, setData] = useState({ nodes: [] });
  const [dataStore, setdataStore] = useState([]);
  const [dataPerson, setdataPerson] = useState([]);
  const [selectedStore, setselectedStore] = useState("");
  const [noTransaction, setnoTransaction] = useState("");
  const [selectedPerson, setselectedPerson] = useState();
  const Token = localStorage.getItem("token");
  const [type, settype] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [filter, setfilter] = useState({
    startDate: new Date(),
    endDate: new Date(),
    storeId: "",
  });
  const [totalPurchase, settotalPurchase] = useState({
    total_order: 0,
    sum_purchase_total: 0,
    sum_purchase_voucher: 0,
    sum_purchase_incentive: 0,
    sum_purchase_shipping_cost: 0,
    sum_purchase_handling_fee: 0,
    sum_purchase_grand_total: 0,
    sum_purchase_quantity: 0,
  });

  const [masterData, setmasterData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);

  const WW = window.innerWidth; // Mendapatkan nilai lebar layar menggunakan breakpoint
  const [sizesColumn, setSizesColumn] = useState("200px");
  const [search, setSearch] = React.useState("");
  const navigate = useNavigate();
  const chakraTheme = getTheme(DEFAULT_OPTIONS);
  const customProduct = useTheme({
    Table: `
      --data-table-library_grid-template-columns: ${
        WW < 770 ? "100px" : "100px"
      }   ${sizesColumn}  ${sizesColumn} ${sizesColumn}  ${sizesColumn} 400px ${sizesColumn} ${sizesColumn}  ${sizesColumn}  ${sizesColumn} ${sizesColumn}  ${sizesColumn} ${sizesColumn}    minmax(150px, 1fr);
    `,

    Row: `
      &:hover {
          background-color: #d2fbf2;
          cursor: pointer; /* Add this line to change cursor to pointer on hover */
        }`,
  });
  const customTrans = useTheme({
    Table: `
      --data-table-library_grid-template-columns: ${
        WW < 770 ? "100px" : "100px"
      }     ${sizesColumn} ${sizesColumn}  ${sizesColumn} ${sizesColumn} ${sizesColumn} ${sizesColumn}  ${sizesColumn}  ${sizesColumn} ${sizesColumn}  ${sizesColumn} ${sizesColumn}    minmax(150px, 1fr);
    `,

    Row: `
      &:hover {
          background-color: #d2fbf2;
          cursor: pointer; /* Add this line to change cursor to pointer on hover */
        }`,
  });

  const [customTheme, setcustomTheme] = useState(customTrans);

  const theme = [chakraTheme, customTheme];
  const [isLoading, setisLoading] = useState(false);

  const fetchData = () => {
    setisLoading(true);
    setData({ nodes: [] });
    onClose();
    get({
      url:
        URL.getReportPurchaseURL +
        `?transaction_no=${search}&store_id=${
          selectedStore ? selectedStore.id : ""
        }&from_date=${format(filter.startDate, "yyyy-MM-dd")}&to_date=${format(
          filter.endDate,
          "yyyy-MM-dd"
        )}&type=${type.value}`,
      needAuth: true,
      showToast: false,
    }).then((response) => {
      const a = response.data.data.purchase_list;
      setSearch("");

      if (type.value === "trans") {
        settotalPurchase({
          total_order: response.data.data.total_order,
          sum_purchase_total: response.data.data.sum_purchase_total,
          sum_purchase_voucher: response.data.data.sum_purchase_voucher,
          sum_purchase_incentive: response.data.data.sum_purchase_incentive,
          sum_purchase_shipping_cost:
            response.data.data.sum_purchase_shipping_cost,
          sum_purchase_handling_fee:
            response.data.data.sum_purchase_handling_fee,
          sum_purchase_grand_total: response.data.data.sum_purchase_grand_total,
        });
      } else {
        settotalPurchase({
          sum_purchase_total: response.data.data.sum_purchase_total,
          sum_purchase_discount: response.data.data.sum_purchase_discount,
          sum_purchase_subtotal: response.data.data.sum_purchase_subtotal,
          sum_purchase_quantity: response.data.data.sum_purchase_quantity,
        });
      }
      const flattenedData = a.map(flattenObject);

      // Extract keys from all objects in flattenedData
      const keys = Object.keys(flattenedData[0] || {});

      // Construct headerString dynamically
      const headerString = keys.join(",") + "\n";
      const rowString = flattenedData
        .map((d) => {
          const mappedData = keys.map((key) => {
            return d[key] || ""; // Return empty string if value is undefined
          });

          return `${mappedData.join(",")}\n`;
        })
        .join("");
      const csvString = `${headerString}${rowString}`;

      setdataCsv(csvString);
      setData({
        nodes: a,
      });
      setmasterData({
        nodes: response.data.data.data,
      });
      setisLoading(false);
    });
  };
  const fetchDataStore = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url: URL.getListStoreURL + `?search=${input}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setisLoading(false);
      allData = res.data.data.data;
    });
    return allData;
  };
  const loadOptionsStore = (inputValue) => {
    return fetchDataStore(inputValue);
  };
  const flattenObject = (obj, prefix = "") =>
    Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? prefix + "." : "";
      if (
        typeof obj[k] === "object" &&
        obj[k] !== null &&
        !Array.isArray(obj[k])
      ) {
        // Exclude specific keys
        if (
          ![
            "created_at",
            "created_by",
            "updated_at",
            "updated_by",
            "deleted_at",
            "deleted_by",
          ].includes(k)
        ) {
          Object.assign(acc, flattenObject(obj[k], pre + k));
        }
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});

  const handleType = (e) => {
    setData({ nodes: [] });
    settype(e);
  };
  const exportToExcel = async () => {
    if (type.value === "trans") {
      const wb = new ExcelJS.Workbook();

      // Create Sheet
      const ws = wb.addWorksheet();

      // Set lebar Column menggunakan key bisa dengan huruf bisa dengan index angka
      ws.getColumn(1).width = 30;
      ws.getColumn(2).width = 30;
      ws.getColumn(3).width = 30;
      ws.getColumn(4).width = 30;
      ws.getColumn(5).width = 30;
      ws.getColumn(6).width = 30;
      ws.getColumn(7).width = 30;
      ws.getColumn(8).width = 30;
      ws.getColumn(9).width = 30;
      ws.getColumn(10).width = 30;
      ws.getColumn(11).width = 30;
      ws.getColumn(12).width = 30;

      // inisiasi pada baris ke 3 jadi Header table
      const rowHeader = ws.getRow(1);

      // Buat styling cell header menggunakan perulangan agar tidak per cell kita bikinnya
      for (let i = 1; i <= 12; i++) {
        // Untuk border table
        rowHeader.getCell(i).border = {
          top: { style: "Regular" },
          left: { style: "Regular" },
          bottom: { style: "Regular" },
          right: { style: "Regular" },
        };
        // Untuk fill color cell
        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: "white",
        };
        // Untuk alignment text dalam cell
        rowHeader.getCell(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        // Untuk set font
        rowHeader.getCell(i).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }

      // Isi data Header

      rowHeader.getCell(1).value = "Transaction No";
      rowHeader.getCell(2).value = "Date";
      rowHeader.getCell(3).value = "Store Code";
      rowHeader.getCell(4).value = "Store Name";
      rowHeader.getCell(5).value = "Store Address";
      rowHeader.getCell(6).value = "Item Count";
      rowHeader.getCell(7).value = "Total";
      rowHeader.getCell(8).value = "Incentive";
      rowHeader.getCell(9).value = "Voucher";
      rowHeader.getCell(10).value = "Shipping Cost";
      rowHeader.getCell(11).value = "Handling Fee";
      rowHeader.getCell(12).value = "Grand Total";

      // Buat datanya menggunakan perulangan
      let no = 1;
      for (let i = 1; i <= data.nodes?.length; i++) {
        const row = ws.getRow(i + 1);
        row.getCell(1).value = data.nodes[i - 1].transaction_no;
        row.getCell(2).value = new Date(
          data.nodes[i - 1].created_at
        ).toLocaleDateString("id-ID");
        row.getCell(3).value = data.nodes[i - 1].store.code;
        row.getCell(4).value = data.nodes[i - 1].store.name;
        row.getCell(5).value = data.nodes[i - 1].store.address;
        row.getCell(6).value = data.nodes[i - 1].purchase_details_count;
        row.getCell(7).value = data.nodes[i - 1].total;
        row.getCell(8).value = data.nodes[i - 1].incentive;
        row.getCell(9).value = data.nodes[i - 1].voucher;
        row.getCell(10).value = data.nodes[i - 1].shipping_cost;
        row.getCell(11).value = data.nodes[i - 1].handling_fee;
        row.getCell(12).value = data.nodes[i - 1].grand_total;

        no++;
      }
      const rowFooter = ws.getRow(data.nodes?.length + 2);

      rowFooter.getCell(7).value = totalPurchase.sum_purchase_total;
      rowFooter.getCell(8).value = totalPurchase.sum_purchase_incentive;
      rowFooter.getCell(9).value = totalPurchase.sum_purchase_voucher;

      rowFooter.getCell(10).value = totalPurchase.sum_purchase_handling_fee;
      rowFooter.getCell(11).value = totalPurchase.sum_purchase_shipping_cost;
      rowFooter.getCell(12).value = totalPurchase.sum_purchase_grand_total;

      for (let index = 1; index <= 12; index++) {
        rowFooter.getCell(index).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }
      //membuat buffer file
      const buf = await wb.xlsx.writeBuffer();

      //download file dari browser dan menamai filenya
      saveAs(
        new Blob([buf]),
        `Report Purchase - ${type.label} - ${formatedDate(new Date())}.xlsx`
      );
    } else {
      const wb = new ExcelJS.Workbook();

      // Create Sheet
      const ws = wb.addWorksheet();

      // Set lebar Column menggunakan key bisa dengan huruf bisa dengan index angka
      ws.getColumn(1).width = 30;
      ws.getColumn(2).width = 30;
      ws.getColumn(3).width = 30;
      ws.getColumn(4).width = 30;
      ws.getColumn(5).width = 30;
      ws.getColumn(6).width = 30;
      ws.getColumn(7).width = 30;
      ws.getColumn(8).width = 30;
      ws.getColumn(9).width = 30;
      ws.getColumn(10).width = 30;
      ws.getColumn(11).width = 30;
      ws.getColumn(12).width = 30;
      ws.getColumn(13).width = 30;
      ws.getColumn(14).width = 30;
      ws.getColumn(15).width = 30;

      // inisiasi pada baris ke 3 jadi Header table
      const rowHeader = ws.getRow(1);

      // Buat styling cell header menggunakan perulangan agar tidak per cell kita bikinnya
      for (let i = 1; i <= 15; i++) {
        // Untuk border table
        rowHeader.getCell(i).border = {
          top: { style: "Regular" },
          left: { style: "Regular" },
          bottom: { style: "Regular" },
          right: { style: "Regular" },
        };
        // Untuk fill color cell
        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: "white",
        };
        // Untuk alignment text dalam cell
        rowHeader.getCell(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        // Untuk set font
        rowHeader.getCell(i).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }

      // Isi data Header
      rowHeader.getCell(1).value = "Transaction No";
      rowHeader.getCell(2).value = "Date";
      rowHeader.getCell(3).value = "Store Code";
      rowHeader.getCell(4).value = "Store Name";
      rowHeader.getCell(5).value = "Store Address";
      rowHeader.getCell(6).value = "Product Code";
      rowHeader.getCell(7).value = "Product Name";
      rowHeader.getCell(8).value = "Price";
      rowHeader.getCell(9).value = "Quantity";
      rowHeader.getCell(10).value = "Unit";
      rowHeader.getCell(11).value = "Total";
      rowHeader.getCell(12).value = "Discount";
      rowHeader.getCell(13).value = "Grand Total";

      // Buat datanya menggunakan perulangan
      let no = 1;
      for (let i = 1; i <= data.nodes?.length; i++) {
        const row = ws.getRow(i + 1);
        row.getCell(1).value = data.nodes[i - 1].purchase_header.transaction_no;
        row.getCell(2).value = new Date(
          data.nodes[i - 1].created_at
        ).toLocaleDateString("id-ID");
        row.getCell(3).value = data.nodes[i - 1].purchase_header.store.code;
        row.getCell(4).value = data.nodes[i - 1].purchase_header.store.name;
        row.getCell(5).value = data.nodes[i - 1].purchase_header.store.address;
        row.getCell(6).value = data.nodes[i - 1].product.code;
        row.getCell(7).value = data.nodes[i - 1].product.name;
        row.getCell(8).value = data.nodes[i - 1].price;
        row.getCell(9).value = data.nodes[i - 1].quantity;
        row.getCell(10).value = data.nodes[i - 1].product.unit.name;
        row.getCell(11).value = data.nodes[i - 1].total;
        row.getCell(12).value = data.nodes[i - 1].discount;
        row.getCell(13).value = data.nodes[i - 1].subtotal;

        no++;
      }

      const rowFooter = ws.getRow(data.nodes?.length + 2);

      rowFooter.getCell(9).value = totalPurchase.sum_purchase_quantity;
      rowFooter.getCell(11).value = totalPurchase.sum_purchase_total;
      rowFooter.getCell(12).value = totalPurchase.sum_purchase_discount;
      rowFooter.getCell(13).value = totalPurchase.sum_purchase_subtotal;

      for (let index = 8; index <= 13; index++) {
        rowFooter.getCell(index).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }
      //membuat buffer file
      const buf = await wb.xlsx.writeBuffer();

      //download file dari browser dan menamai filenya
      saveAs(
        new Blob([buf]),
        `Report Purchase - ${type.label} - ${formatedDate(new Date())}.xlsx`
      );
    }
  };

  useEffect(() => {
    if (WW < 770) {
      setSizesColumn("200px");
    } else {
      setSizesColumn("200px");
    }
  }, [currentPage]);

  useEffect(() => {
    if (type.value === "trans") {
      setcustomTheme(customTrans);
    } else {
      setcustomTheme(customProduct);
    }
  }, [type]);
  return (
    <Box bg="white" p="5" rounded={"md"}>
      <Box mb={5}>
        <Text fontSize={[14, 16, 18]} fontFamily={"Semibold"} color="black">
          Purchase
        </Text>
      </Box>
      <Wrap spacing={10}>
        <WrapItem zIndex={6}>
          <Stack>
            <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
              Type
            </Text>
            <Box w="250px">
              <Select
                value={type}
                onChange={handleType}
                options={[
                  { id: "trans", title: "Transaction" },
                  { id: "product", title: "Product" },
                ].map((item) => ({
                  value: item.id,
                  label: item.title,
                }))}
              />
            </Box>
          </Stack>
        </WrapItem>
        <WrapItem zIndex={5}>
          <Stack>
            <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
              Start Date
            </Text>

            <HStack flex={0.2} borderWidth={1} p={2} borderRadius={5}>
              <ReactDatePicker
                dateFormat="dd-MM-yyyy"
                selected={filter.startDate}
                onChange={(date) =>
                  setfilter((prevState) => ({
                    ...prevState,
                    startDate: new Date(date),
                  }))
                }
              />
              <Calendar color="black" />
            </HStack>
          </Stack>
        </WrapItem>
        <WrapItem zIndex={2}>
          <Stack>
            <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
              End Date
            </Text>
            <HStack flex={0.2} borderWidth={1} p={2} borderRadius={5}>
              <ReactDatePicker
                dateFormat="dd-MM-yyyy"
                selected={filter.endDate}
                onChange={(date) =>
                  setfilter((prevState) => ({
                    ...prevState,
                    endDate: new Date(date),
                  }))
                }
              />
              <Calendar color="black" />
            </HStack>
          </Stack>
        </WrapItem>
        <WrapItem>
          <Stack>
            <Text color="white" fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
              Period
            </Text>
            <Button
              isDisabled={!type}
              w="20"
              colorScheme="teal"
              onClick={fetchData}
            >
              <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                Filter
              </Text>
            </Button>
          </Stack>
        </WrapItem>
        <WrapItem>
          <Stack display={{ base: "flex" }}>
            <Text color="white" fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
              Period
            </Text>
            <Button colorScheme="teal" onClick={onOpen}>
              <HStack>
                <ICFilter size="20" color="white" />
                <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                  Advance Filter
                </Text>
              </HStack>
            </Button>
            <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">
                  <Text fontFamily={"Semibold"}>Advance Filter</Text>
                </DrawerHeader>
                <DrawerBody>
                  <Stack spacing="24px">
                    <Stack>
                      <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                        Type
                      </Text>
                      <Box w="250px">
                        <Select
                          value={type}
                          onChange={handleType}
                          options={[
                            { id: "trans", title: "Transaction" },
                            { id: "product", title: "Product" },
                          ].map((item) => ({
                            value: item.id,
                            label: item.title,
                          }))}
                        />
                      </Box>
                    </Stack>
                    <Box>
                      <Stack>
                        <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                          Transaction No
                        </Text>
                        <Box>
                          <Input
                            onChange={(e) => setSearch(e.target.value)}
                            fontFamily={"Regular"}
                            id="username"
                            placeholder="Search Transaction Nos"
                          />
                        </Box>
                      </Stack>
                    </Box>

                    <Stack>
                      <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                        Start Date
                      </Text>

                      <HStack borderWidth={1} p={2} borderRadius={5}>
                        <ReactDatePicker
                          dateFormat="dd-MM-yyyy"
                          selected={filter.startDate}
                          onChange={(date) =>
                            setfilter((prevState) => ({
                              ...prevState,
                              startDate: new Date(date),
                            }))
                          }
                        />
                        <Calendar color="black" />
                      </HStack>
                    </Stack>
                    <Stack>
                      <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                        End Date
                      </Text>

                      <HStack borderWidth={1} p={2} borderRadius={5}>
                        <ReactDatePicker
                          dateFormat="dd-MM-yyyy"
                          selected={filter.endDate}
                          onChange={(date) =>
                            setfilter((prevState) => ({
                              ...prevState,
                              endDate: new Date(date),
                            }))
                          }
                        />
                        <Calendar color="black" />
                      </HStack>
                    </Stack>
                    <Box>
                      <Stack>
                        <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                          Store
                        </Text>
                        <Box>
                          <AsyncSelect
                            isClearable
                            value={selectedStore}
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.id}
                            onChange={(e) => setselectedStore(e)}
                            cacheOptions={false}
                            loadOptions={loadOptionsStore}
                            defaultOptions={false}
                          />
                        </Box>
                      </Stack>
                    </Box>
                  </Stack>
                </DrawerBody>
                <DrawerFooter borderTopWidth="1px">
                  <Button variant="outline" mr={3} onClick={onClose}>
                    <Text fontFamily={"Semibold"}>Cancel</Text>
                  </Button>
                  <Button
                    isDisabled={!type}
                    colorScheme="teal"
                    onClick={fetchData}
                  >
                    <Text fontFamily={"Semibold"}>Submit</Text>
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          </Stack>
        </WrapItem>
        {data.nodes?.length > 0 && (
          <WrapItem justifyContent={"flex-end"} flex={1}>
            <HStack>
              <Stack display={{ base: "flex" }}>
                <Text
                  color="white"
                  fontFamily={"SemiBold"}
                  fontSize={FONTSIZES.md}
                >
                  Period
                </Text>

                <Button w="30" colorScheme="teal" onClick={exportToExcel}>
                  <HStack>
                    <MyIcon name={"file-down"} size={18} color="white" />
                    <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                      Export
                    </Text>
                  </HStack>
                </Button>
              </Stack>
            </HStack>
          </WrapItem>
        )}
      </Wrap>
      <br />
      {type.value === "trans" ? (
        <Box p={1} borderWidth="1px" borderRadius="lg">
          {isLoading ? (
            <MyLoader />
          ) : data.nodes?.length > 0 ? (
            <Table
              layout={{ custom: true, horizontalScroll: true }}
              data={data}
              theme={theme}
            >
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          No
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Transaction No
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Date
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Store Code
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Store Name
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Store Address
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Item
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Total
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Incentive
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Voucher
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Shipping Cost
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Handling Fee
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Grand Total
                        </Text>
                      </HeaderCell>
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item, index) => (
                      <Row key={item.id} item={item}>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            {index + 1}
                          </Text>
                        </Cell>

                        <Cell>
                          <HStack>
                            <Text
                              fontFamily={"Regular"}
                              color="black"
                              fontSize={FONTSIZES.md}
                            >
                              {item.transaction_no}
                            </Text>
                          </HStack>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            {formatedDate(item.created_at)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            {item.store.code}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            {item.store.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            {item.store.address}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            {item.purchase_details_count} ITEM
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            Rp. {formattedCurrency(item.total)}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            Rp. {formattedCurrency(item.incentive)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            Rp. {formattedCurrency(item.voucher)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            Rp. {formattedCurrency(item.shipping_cost)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            Rp. {formattedCurrency(item.handling_fee)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            Rp. {formattedCurrency(item.grand_total)}
                          </Text>
                        </Cell>
                      </Row>
                    ))}
                  </Body>
                  <Footer>
                    <FooterRow>
                      <FooterCell></FooterCell>
                      <FooterCell></FooterCell>
                      <FooterCell></FooterCell>
                      <FooterCell></FooterCell>
                      <FooterCell></FooterCell>
                      <FooterCell></FooterCell>
                      <FooterCell></FooterCell>
                      <FooterCell>
                        <Text fontFamily={"Bold"} fontSize={12} color={"black"}>
                          Rp{" "}
                          {formattedCurrency(totalPurchase.sum_purchase_total)}
                        </Text>
                      </FooterCell>

                      <FooterCell>
                        <Text fontFamily={"Bold"} fontSize={12} color={"black"}>
                          <Text
                            fontFamily={"Bold"}
                            fontSize={12}
                            color={"black"}
                          >
                            Rp{" "}
                            {formattedCurrency(
                              totalPurchase.sum_purchase_incentive
                            )}
                          </Text>
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text fontFamily={"Bold"} fontSize={12} color={"black"}>
                          <Text
                            fontFamily={"Bold"}
                            fontSize={12}
                            color={"black"}
                          >
                            Rp{" "}
                            {formattedCurrency(
                              totalPurchase.sum_purchase_voucher
                            )}
                          </Text>
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text fontFamily={"Bold"} fontSize={12} color={"black"}>
                          <Text
                            fontFamily={"Bold"}
                            fontSize={12}
                            color={"black"}
                          >
                            Rp{" "}
                            {formattedCurrency(
                              totalPurchase.sum_purchase_shipping_cost
                            )}
                          </Text>
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text fontFamily={"Bold"} fontSize={12} color={"black"}>
                          Rp{" "}
                          {formattedCurrency(
                            totalPurchase.sum_purchase_handling_fee
                          )}
                        </Text>
                      </FooterCell>

                      <FooterCell>
                        <Text fontFamily={"Bold"} fontSize={12} color={"black"}>
                          <Text
                            fontFamily={"Bold"}
                            fontSize={12}
                            color={"black"}
                          >
                            Rp{" "}
                            {formattedCurrency(
                              totalPurchase.sum_purchase_grand_total
                            )}
                          </Text>
                        </Text>
                      </FooterCell>
                    </FooterRow>
                  </Footer>
                </>
              )}
            </Table>
          ) : (
            <Stack mt={5}>
              <Center>
                <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
                  Data Not Found
                </Text>
              </Center>
              <Lottie
                options={{
                  animationData: emptyLotie,
                }}
                width={"20vw"}
              />
            </Stack>
          )}
        </Box>
      ) : (
        // Report Prod
        <Box p={1} borderWidth="1px" borderRadius="lg">
          {isLoading ? (
            <MyLoader />
          ) : data.nodes?.length > 0 ? (
            <Table
              layout={{ custom: true, horizontalScroll: true }}
              data={data}
              theme={theme}
            >
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          No
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Transaction No
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Date
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Store Code
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Store Name
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Store Address
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Product Code
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Product Name
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Price
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Quantity
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Unit
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Total
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Discount
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text fontFamily={"Bold"} color={"black"}>
                          Grand Total
                        </Text>
                      </HeaderCell>
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item, index) => (
                      <Row key={item.id} item={item}>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            {index + 1}
                          </Text>
                        </Cell>

                        <Cell>
                          <HStack>
                            <Text
                              fontFamily={"Regular"}
                              color="black"
                              fontSize={FONTSIZES.md}
                            >
                              {item.purchase_header.transaction_no}
                            </Text>
                          </HStack>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            {formatedDate(item.created_at)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            {item.purchase_header.store.code}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            {item.purchase_header.store.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            {item.purchase_header.store.address}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            {item.product.code}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            {item.product.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            Rp. {formattedCurrency(item.price)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            {item.quantity}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            {item.product.unit.name}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            Rp. {formattedCurrency(item.total)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            Rp. {formattedCurrency(item.discount)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontFamily={"Regular"}
                            color="black"
                            fontSize={FONTSIZES.md}
                          >
                            Rp. {formattedCurrency(item.subtotal)}
                          </Text>
                        </Cell>
                      </Row>
                    ))}
                  </Body>
                  <Footer>
                    <FooterRow>
                      <FooterCell>
                        <Text fontFamily={"Bold"} color={"black"}></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text fontFamily={"Bold"} color={"black"}></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text fontFamily={"Bold"} color={"black"}></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text fontFamily={"Bold"} color={"black"}></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text fontFamily={"Bold"} color={"black"}></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text fontFamily={"Bold"} color={"black"}></Text>
                      </FooterCell>

                      <FooterCell>
                        <Text fontFamily={"Bold"} color={"black"}></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text fontFamily={"Bold"} color={"black"}></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text fontFamily={"Bold"} color={"black"}></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text fontFamily={"Bold"} color={"black"} fontSize={12}>
                          {totalPurchase.sum_purchase_quantity}
                        </Text>
                      </FooterCell>

                      <FooterCell>
                        <Text fontFamily={"Bold"} color={"black"}></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text fontSize={12} fontFamily={"Bold"} color={"black"}>
                          Rp{" "}
                          {formattedCurrency(totalPurchase.sum_purchase_total)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text fontSize={12} fontFamily={"Bold"} color={"black"}>
                          Rp{" "}
                          {formattedCurrency(
                            totalPurchase.sum_purchase_discount
                          )}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text fontSize={12} fontFamily={"Bold"} color={"black"}>
                          Rp{" "}
                          {formattedCurrency(
                            totalPurchase.sum_purchase_subtotal
                          )}
                        </Text>
                      </FooterCell>
                    </FooterRow>
                  </Footer>
                </>
              )}
            </Table>
          ) : (
            <Stack mt={5}>
              <Center>
                <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
                  Data Not Found
                </Text>
              </Center>
              <Lottie
                options={{
                  animationData: emptyLotie,
                }}
                width={"20vw"}
              />
            </Stack>
          )}
        </Box>
      )}
      <br />
      {/* {data.nodes?.length > 0 && <PaginationNav />} */}

      <br />
    </Box>
  );
};

export default ReportPurchase;
