import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Progress,
  SimpleGrid,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
import AnimatedContainer from "../../component/animatedContainer/AnimatedContainer";
import Footer from "../../component/footer/Index";
import { URL } from "../../utils/appURL";

const Login = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);
  const [isLoading, setisLoading] = useState(false);
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [isError, setisError] = useState({
    username: "Required",
    password: "Required",
  });
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token == "null" || !token) {
      navigate("/login");
    } else {
      navigate("/");
    }
  }, [token, navigate]);
  const handleLogin = () => {
    setisLoading(true);
    axios
      .post(URL.loginURL, {
        username: username,
        password: password,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.data.access_token);
        setisLoading(false);
        toast({
          title: "Success",
          description: res.data.message,
          status: "success",
          duration: 1000,
          position: "top",
        });
        navigate("/");
      })
      .catch((err) => {
        setisLoading(false);
        toast({
          title: err.response?.data.message,
          description: err.response.data.message,
          status: "error",
          duration: 1000,
          position: "top",
        });
      });
  };
  const handleChangeUsername = (event) => {
    setusername(event.target.value);
    if (event.target.value === "") {
      setisError((prevState) => ({
        ...prevState,
        username: "Required",
      }));
    } else {
      setisError((prevState) => ({
        ...prevState,
        username: "Must be more than 4 character",
      }));
    }
  };

  const handleChangePassword = (event) => {
    setpassword(event.target.value);
    if (event.target.value === "") {
      setisError((prevState) => ({
        ...prevState,
        password: "Required",
      }));
    } else {
      setisError((prevState) => ({
        ...prevState,
        password: "Must be more than 4 character",
      }));
    }
  };

  return (
    <Box h={"100vh"} bgGradient="linear(to-br, teal.900,teal.600)">
      {isLoading && (
        <Center
          position={"absolute"}
          h="100%"
          w={"100%"}
          bg="rgba(0,0,0,0.5)"
          zIndex={2}
        >
          <Progress
            w="50%"
            rounded={"full"}
            size={"sm"}
            colorScheme={"teal"}
            isIndeterminate
          />
        </Center>
      )}
      <Container
        as={SimpleGrid}
        maxW={"7xl"}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 24 }}
      >
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: "-15vh" }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <Stack alignItems={"center"} spacing={{ base: 10, md: 20 }}>
              <Image boxSize={["70%", "100%", "100%"]} src={Logo} />
            </Stack>
          </motion.div>
        </AnimatePresence>
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: "15vh" }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <Stack
              bg={"gray.50"}
              rounded={"xl"}
              p={{ base: 4, sm: 6, md: 8 }}
              spacing={{ base: 8 }}
              maxW={{ lg: "lg" }}
            >
              <Stack spacing={5}>
                <Heading
                  color={"gray.800"}
                  lineHeight={1.1}
                  fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
                  fontFamily={"Bold"}
                >
                  Login
                  <Text
                    fontFamily={"Bold"}
                    as={"span"}
                    bgGradient="linear(to-r, teal.900,teal.100)"
                    bgClip="text"
                  >
                    !
                  </Text>
                </Heading>
                <Text
                  color={"gray.500"}
                  fontSize={{ base: "sm", sm: "md" }}
                  fontFamily={"Medium"}
                >
                  Login required
                </Text>
              </Stack>
              <Box as={"form"}>
                <Stack spacing={5}>
                  <Text
                    fontSize={{ base: "sm", sm: "md" }}
                    fontFamily={"Medium"}
                  >
                    Username
                  </Text>
                  <Stack>
                    <Input
                      isInvalid={username.length < 4}
                      colorScheme={"yellow"}
                      fontFamily={"Medium"}
                      onChange={handleChangeUsername}
                      fontSize={{ base: "sm", sm: "md" }}
                      placeholder="Username"
                      bg={"gray.100"}
                      border={0}
                      color={"black"}
                      _placeholder={{
                        color: "gray.500",
                      }}
                    />
                    {username.length < 4 && (
                      <AnimatedContainer>
                        <Text
                          color="red.500"
                          fontSize={"xs"}
                          fontFamily={"Medium"}
                        >
                          {isError.username}
                        </Text>
                      </AnimatedContainer>
                    )}
                  </Stack>
                  <Text
                    fontSize={{ base: "sm", sm: "md" }}
                    fontFamily={"Medium"}
                  >
                    Password
                  </Text>
                  <Stack>
                    <InputGroup>
                      <Input
                        fontFamily={"Medium"}
                        onChange={handleChangePassword}
                        type={show ? "text" : "password"}
                        fontSize={{ base: "sm", sm: "md" }}
                        placeholder="Password"
                        bg={"gray.100"}
                        border={0}
                        color={"black"}
                        _placeholder={{
                          color: "gray.500",
                        }}
                      />
                      <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" onClick={handleClick}>
                          {show ? (
                            <ViewOffIcon color={"teal.500"} />
                          ) : (
                            <ViewIcon color="teal.500" />
                          )}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    {password.length < 4 && (
                      <AnimatedContainer>
                        <Text
                          color="red.500"
                          fontSize={"xs"}
                          fontFamily={"Medium"}
                        >
                          {isError.password}
                        </Text>
                      </AnimatedContainer>
                    )}
                  </Stack>
                </Stack>
                <Button
                  isDisabled={username.length < 4 || password.length < 4}
                  onClick={() => handleLogin()}
                  fontFamily={"heading"}
                  mt={8}
                  w={"full"}
                  bgGradient="linear(to-r, teal.700,teal.400)"
                  color={"white"}
                  _hover={{
                    bgGradient: "linear(to-r, teal.700,teal.400)",
                    boxShadow: "xl",
                  }}
                >
                  <Text
                    fontFamily={"Medium"}
                    fontSize={{ base: "sm", sm: "md" }}
                  >
                    Login
                  </Text>
                </Button>
              </Box>
            </Stack>
          </motion.div>
        </AnimatePresence>
      </Container>
      <Footer />
    </Box>
  );
};
export default Login;
