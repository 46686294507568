import {
  Box,
  Button,
  Center,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  Input,
  Stack,
  Text,
  useDisclosure,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import * as React from "react";
import ICFilter from "../../../assets/icons/ICFilter";
// import ICSearch from "../../../assets/icons/ICSearch";
import {
  Body,
  Cell,
  Footer,
  FooterCell,
  FooterRow,
  Header,
  HeaderCell,
  HeaderRow,
  Row,
  Table,
} from "@table-library/react-table-library";
import {
  DEFAULT_OPTIONS,
  getTheme,
} from "@table-library/react-table-library/chakra-ui";
import { usePagination } from "@table-library/react-table-library/pagination";
import { useTheme } from "@table-library/react-table-library/theme";
import { AsyncSelect, Select } from "chakra-react-select";
import { format } from "date-fns";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Calendar } from "lucide-react";
import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import Lottie from "react-lottie";
import emptyLotie from "../../../assets/lotties/empty.json";
import MyLoader from "../../../component/loader/MyLoader";
import MyIcon from "../../../component/MyIcon.js/Index";
import { FONTSIZES } from "../../../theme/FontSize";
import { URL } from "../../../utils/appURL";
import { formattedCurrency } from "../../../utils/formatedCurrency";
import formatedDate from "../../../utils/formatedDate";
import { useHttp } from "../../../utils/http";
const ReportPaymentBill = ({ label }) => {
  const { get } = useHttp();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [noTransaction, setnoTransaction] = useState("");

  const [data, setData] = useState({ nodes: [] });

  const [type, settype] = useState("");
  const [totalSales, settotalSales] = useState(0);

  const [filter, setfilter] = useState({
    startDate: new Date(),
    endDate: new Date(),
    storeId: "",
  });
  const [dataCsv, setdataCsv] = useState([]);

  const [selectedStore, setselectedStore] = useState("");
  const [masterData, setmasterData] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);

  const WW = window.innerWidth;
  const [sizesColumn, setSizesColumn] = useState("200px");
  const chakraTheme = getTheme(DEFAULT_OPTIONS);
  const customProduct = useTheme({
    Table: `
      --data-table-library_grid-template-columns: ${
        WW < 770 ? "100px" : "100px"
      } ${sizesColumn} ${sizesColumn}   ${sizesColumn} ${sizesColumn}   ${sizesColumn}  ${sizesColumn} ${sizesColumn} ${sizesColumn}   ${sizesColumn} ${sizesColumn} ${sizesColumn}  ${sizesColumn}  ${sizesColumn} ${sizesColumn}  ${sizesColumn} ${sizesColumn}    minmax(150px, 1fr);
    `,

    Row: `
      &:hover {
          background-color: #d2fbf2;
          cursor: pointer; /* Add this line to change cursor to pointer on hover */
        }`,
  });

  const customTrans = useTheme({
    Table: `
      --data-table-library_grid-template-columns: ${
        WW < 770 ? "100px" : "100px"
      } ${sizesColumn} ${sizesColumn}   ${sizesColumn} ${sizesColumn}   ${sizesColumn}  ${sizesColumn} ${sizesColumn} ${sizesColumn}   ${sizesColumn}     ${sizesColumn}  ${sizesColumn} ;
    `,

    Row: `
      &:hover {
          background-color: #d2fbf2;
          cursor: pointer; /* Add this line to change cursor to pointer on hover */
        }`,
  });

  const [customTheme, setcustomTheme] = useState(customTrans);

  const theme = [chakraTheme, customTheme];
  const [isLoading, setisLoading] = useState(true);
  const pagination = usePagination(data, {
    state: {
      page: 0,
      size: 10,
    },
    onChange: onPaginationChange,
  });
  const fetchData = () => {
    setisLoading(true);
    get({
      url:
        URL.getReportPaymentBillURL +
        `?store_id=${selectedStore ? selectedStore.id : ""}&from_date=${format(
          filter.startDate,
          "yyyy-MM-dd"
        )}&to_date=${format(filter.endDate, "yyyy-MM-dd")}&type=${
          type.value
        }&search=${noTransaction}`,
      needAuth: true,
      showToast: false,
    }).then((response) => {
      const a = response.data.data.payment_list;
      settotalSales(response.data.data);

      // Flatten nested objects in allData
      const flattenedData = a.map(flattenObject);

      // Extract keys from all objects in flattenedData
      const keys = Object.keys(flattenedData[0] || {});

      // Construct headerString dynamically
      const headerString = keys.join(",") + "\n";
      const rowString = flattenedData
        .map((d) => {
          const mappedData = keys.map((key) => {
            return d[key] || ""; // Return empty string if value is undefined
          });

          return `${mappedData.join(",")}\n`;
        })
        .join("");
      const csvString = `${headerString}${rowString}`;

      setdataCsv(csvString);
      setData({
        nodes: a,
      });
      setmasterData({
        nodes: a,
      });
      setisLoading(false);
    });
  };
  const fetchDataStore = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url: URL.getListStoreURL + `?search=${input}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((response) => {
      allData = response.data.data.data;
    });
    return allData;
  };
  const loadOptionsStore = (inputValue) => {
    return fetchDataStore(inputValue);
  };
  const flattenObject = (obj, prefix = "") =>
    Object.keys(obj).reduce((acc, k) => {
      const pre = prefix.length ? prefix + "." : "";
      if (
        typeof obj[k] === "object" &&
        obj[k] !== null &&
        !Array.isArray(obj[k])
      ) {
        // Exclude specific keys
        if (
          ![
            "created_at",
            "created_by",
            "updated_at",
            "updated_by",
            "deleted_at",
            "deleted_by",
          ].includes(k)
        ) {
          Object.assign(acc, flattenObject(obj[k], pre + k));
        }
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});

  function onPaginationChange(action, state) {}

  const handleType = (e) => {
    setData({ nodes: [] });
    settype(e);
  };

  const exportToExcel = async () => {
    if (type.value === "payment") {
      const wb = new ExcelJS.Workbook();

      // Create Sheet
      const ws = wb.addWorksheet();

      // Set lebar Column menggunakan key bisa dengan huruf bisa dengan index angka
      ws.getColumn(1).width = 50;
      ws.getColumn(2).width = 50;
      ws.getColumn(3).width = 50;
      ws.getColumn(4).width = 50;
      ws.getColumn(5).width = 50;
      ws.getColumn(6).width = 50;
      ws.getColumn(7).width = 50;
      ws.getColumn(8).width = 50;
      ws.getColumn(9).width = 50;
      ws.getColumn(10).width = 50;
      ws.getColumn(11).width = 50;

      // inisiasi pada baris ke 3 jadi Header table
      const rowHeader = ws.getRow(1);

      // Buat styling cell header menggunakan perulangan agar tidak per cell kita bikinnya
      for (let i = 1; i <= 11; i++) {
        // Untuk border table
        rowHeader.getCell(i).border = {
          top: { style: "Regular" },
          left: { style: "Regular" },
          bottom: { style: "Regular" },
          right: { style: "Regular" },
        };
        // Untuk fill color cell
        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: "white",
        };
        // Untuk alignment text dalam cell
        rowHeader.getCell(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        // Untuk set font
        rowHeader.getCell(i).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }

      // Isi data Header

      rowHeader.getCell(1).value = "Payment No";
      rowHeader.getCell(2).value = "Date";
      rowHeader.getCell(3).value = "Store Code";
      rowHeader.getCell(4).value = "Store Name";
      rowHeader.getCell(5).value = "Store Address";
      rowHeader.getCell(6).value = "Member";
      rowHeader.getCell(7).value = "Payment Method";
      rowHeader.getCell(8).value = "Notes";
      rowHeader.getCell(9).value = "Bill";
      rowHeader.getCell(10).value = "Total Payment";
      rowHeader.getCell(11).value = "Remaining";

      // Buat datanya menggunakan perulangan
      let no = 1;
      for (let i = 1; i <= data.nodes?.length; i++) {
        const row = ws.getRow(i + 1);

        row.getCell(1).value = data.nodes[i - 1].transaction_no;
        row.getCell(2).value = data.nodes[i - 1].payment_date;
        row.getCell(3).value = data.nodes[i - 1]?.store.code;
        row.getCell(4).value = data.nodes[i - 1]?.store.name;
        row.getCell(5).value = data.nodes[i - 1]?.store.address;
        row.getCell(6).value = data.nodes[i - 1].person
          ? data.nodes[i - 1].person.name
          : "";
        row.getCell(7).value = data.nodes[i - 1].payment_method.name;
        row.getCell(8).value = data.nodes[i - 1].notes;
        row.getCell(9).value = data.nodes[i - 1].total_bill;
        row.getCell(10).value = data.nodes[i - 1].total_pay;
        row.getCell(11).value = data.nodes[i - 1].total_remaining;

        no++;
      }

      //create footer
      const rowFooter = ws.getRow(data.nodes?.length + 2);

      rowFooter.getCell(9).value = totalSales.sum_payments_total_bill;
      rowFooter.getCell(10).value = totalSales.sum_payments_total_pay;
      rowFooter.getCell(11).value = totalSales.sum_payments_total_remaining;

      rowFooter.getCell(9).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(10).font = { bold: true, size: 11, color: "black" };
      rowFooter.getCell(11).font = { bold: true, size: 11, color: "black" };
      //membuat buffer file
      const buf = await wb.xlsx.writeBuffer();

      //download file dari browser dan menamai filenya
      saveAs(
        new Blob([buf]),
        `Report Payment Bill - ${type.label} - ${formatedDate(new Date())}.xlsx`
      );
    } else {
      const wb = new ExcelJS.Workbook();

      // Create Sheet
      const ws = wb.addWorksheet();

      // Set lebar Column menggunakan key bisa dengan huruf bisa dengan index angka
      ws.getColumn(1).width = 50;
      ws.getColumn(2).width = 50;
      ws.getColumn(3).width = 50;
      ws.getColumn(4).width = 50;
      ws.getColumn(5).width = 50;
      ws.getColumn(6).width = 50;
      ws.getColumn(7).width = 50;
      ws.getColumn(8).width = 50;
      ws.getColumn(9).width = 50;
      ws.getColumn(10).width = 50;
      ws.getColumn(11).width = 50;
      ws.getColumn(12).width = 50;
      ws.getColumn(13).width = 50;
      ws.getColumn(14).width = 50;
      ws.getColumn(15).width = 50;
      ws.getColumn(16).width = 50;
      ws.getColumn(17).width = 50;

      // inisiasi pada baris ke 3 jadi Header table
      const rowHeader = ws.getRow(1);

      // Buat styling cell header menggunakan perulangan agar tidak per cell kita bikinnya
      for (let i = 1; i <= 17; i++) {
        // Untuk border table
        rowHeader.getCell(i).border = {
          top: { style: "Regular" },
          left: { style: "Regular" },
          bottom: { style: "Regular" },
          right: { style: "Regular" },
        };
        // Untuk fill color cell
        rowHeader.getCell(i).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: "white",
        };
        // Untuk alignment text dalam cell
        rowHeader.getCell(i).alignment = {
          vertical: "middle",
          horizontal: "center",
        };
        // Untuk set font
        rowHeader.getCell(i).font = {
          bold: true,
          size: 11,
          color: "black",
        };
      }

      // Isi data Header
      rowHeader.getCell(1).value = "TRANSACTION NO";
      rowHeader.getCell(2).value = "DATE";
      rowHeader.getCell(3).value = "PAYMENT STATUS";
      rowHeader.getCell(4).value = "PAYMENT METHOD";
      rowHeader.getCell(5).value = "MEMBER";
      rowHeader.getCell(6).value = "STORE CODE";
      rowHeader.getCell(7).value = "STORE NAME";
      rowHeader.getCell(8).value = "STORE ADDRESS";
      rowHeader.getCell(9).value = "ITEM COUNT";
      rowHeader.getCell(10).value = "POINT";
      rowHeader.getCell(11).value = "TOTAL";
      rowHeader.getCell(12).value = "SHIPPING COST";
      rowHeader.getCell(13).value = "TAX";
      rowHeader.getCell(14).value = "VOUCHER";
      rowHeader.getCell(15).value = "GRAND TOTAL";
      rowHeader.getCell(16).value = "TOTAL PAYMENT";
      rowHeader.getCell(17).value = "REMAINING";

      // Buat datanya menggunakan perulangan
      let no = 1;
      for (let i = 1; i <= data.nodes?.length; i++) {
        const row = ws.getRow(i + 1);
        row.getCell(1).value = data.nodes[i - 1].transaction_no;
        row.getCell(1).value = data.nodes[i - 1].transaction_date;
        row.getCell(2).value = data.nodes[i - 1].payment_status.toUpperCase();
        row.getCell(4).value = data.nodes[i - 1].payment_method.name;
        row.getCell(5).value = data.nodes[i - 1].person.name;
        row.getCell(6).value = data.nodes[i - 1].store.code;
        row.getCell(7).value = data.nodes[i - 1].store.name;
        row.getCell(8).value = data.nodes[i - 1].store.address;
        row.getCell(9).value = data.nodes[i - 1].sales_details_count;
        row.getCell(10).value = data.nodes[i - 1].point;
        row.getCell(11).value = data.nodes[i - 1].total;
        row.getCell(12).value = data.nodes[i - 1].delivery_fee;
        row.getCell(13).value = data.nodes[i - 1].tax;
        row.getCell(14).value = data.nodes[i - 1].voucher;
        row.getCell(15).value = data.nodes[i - 1].grand_total;
        row.getCell(16).value = data.nodes[i - 1].payment_details_sum_pay || 0;
        row.getCell(17).value = data.nodes[i - 1].difference;

        no++;
      }

      const rowFooter = ws.getRow(data.nodes?.length + 2);

      const footerRow = rowFooter;
      footerRow.getCell(10).value = totalSales.sum_sales_point;
      footerRow.getCell(11).value = totalSales.sum_sales_total;
      footerRow.getCell(12).value = totalSales.sum_sales_delivery_fee;
      footerRow.getCell(13).value = totalSales.sum_sales_tax;
      footerRow.getCell(14).value = totalSales.sum_sales_voucher;
      footerRow.getCell(15).value = totalSales.sum_sales_grand_total;
      footerRow.getCell(16).value = totalSales.sum_total_pay;
      footerRow.getCell(17).value = totalSales.sum_different_payment;

      footerRow.getCell(10).font = { bold: true, size: 11, color: "black" };
      footerRow.getCell(11).font = { bold: true, size: 11, color: "black" };
      footerRow.getCell(13).font = { bold: true, size: 11, color: "black" };
      footerRow.getCell(14).font = { bold: true, size: 11, color: "black" };
      footerRow.getCell(15).font = { bold: true, size: 11, color: "black" };
      footerRow.getCell(16).font = { bold: true, size: 11, color: "black" };
      footerRow.getCell(17).font = { bold: true, size: 11, color: "black" };

      //membuat buffer file
      const buf = await wb.xlsx.writeBuffer();

      //download file dari browser dan menamai filenya
      saveAs(
        new Blob([buf]),
        `Report Payment Bills - ${type.label} - ${formatedDate(
          new Date()
        )}.xlsx`
      );
    }
  };

  setTimeout(() => {
    setisLoading(false);
  }, 700);
  useEffect(() => {
    if (WW < 770) {
      setSizesColumn("200px");
    } else {
      setSizesColumn("200px");
    }
  }, [currentPage]);
  useEffect(() => {
    if (type.value === "payment") {
      setcustomTheme(customTrans);
    } else {
      setcustomTheme(customProduct);
    }
  }, [type]);
  return (
    <Box bg="white" p="5" rounded={"md"}>
      <Box mb={5}>
        <Text fontSize={[14, 16, 18]} fontFamily={"Semibold"} color="black">
          Payment Bill
        </Text>
      </Box>
      <Wrap spacing={8}>
        <WrapItem zIndex={6}>
          <Stack px="2">
            <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
              Type
            </Text>
            <Box w="250px">
              <Select
                value={type}
                onChange={handleType}
                options={[
                  { id: "payment", title: "Payment" },
                  { id: "sales", title: "Sales" },
                ].map((item) => ({
                  value: item.id,
                  label: item.title,
                }))}
              />
            </Box>
          </Stack>
        </WrapItem>

        <WrapItem zIndex={5}>
          <Stack px="2">
            <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
              Start Date
            </Text>

            <HStack flex={0.2} borderWidth={1} p={2} borderRadius={5}>
              <ReactDatePicker
                dateFormat="dd-MM-yyyy"
                selected={filter.startDate}
                onChange={(date) =>
                  setfilter((prevState) => ({
                    ...prevState,
                    startDate: new Date(date),
                  }))
                }
              />
              <Calendar color="black" fontSize={FONTSIZES.md} />
            </HStack>
          </Stack>
        </WrapItem>
        <WrapItem zIndex={4}>
          <Stack>
            <Text fontSize={FONTSIZES.md} fontFamily={"SemiBold"}>
              End Date
            </Text>
            <HStack flex={0.2} borderWidth={1} p={2} borderRadius={5}>
              <ReactDatePicker
                dateFormat="dd-MM-yyyy"
                selected={filter.endDate}
                onChange={(date) =>
                  setfilter((prevState) => ({
                    ...prevState,
                    endDate: new Date(date),
                  }))
                }
              />
              <Calendar color="black" fontSize={FONTSIZES.md} />
            </HStack>
          </Stack>
        </WrapItem>

        <WrapItem>
          <Stack>
            <Text fontSize={FONTSIZES.md} color="white" fontFamily={"SemiBold"}>
              Period
            </Text>
            <Button w="20" colorScheme="teal" onClick={fetchData}>
              <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                Filter
              </Text>
            </Button>
          </Stack>
        </WrapItem>

        <WrapItem>
          <Stack display={{ base: "flex" }}>
            <Text color="white" fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
              Period
            </Text>
            <Button colorScheme="teal" onClick={onOpen}>
              <HStack>
                <ICFilter size="20" color="white" />
                <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                  Advance Filter
                </Text>
              </HStack>
            </Button>
            <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader borderBottomWidth="1px">
                  <Text fontFamily={"Semibold"}>Advance Filter</Text>
                </DrawerHeader>
                <DrawerBody>
                  <Stack spacing="24px">
                    <Stack>
                      <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                        Type
                      </Text>
                      <Box w="250px">
                        <Select
                          value={type}
                          onChange={handleType}
                          options={[
                            { id: "payment", title: "Payment" },
                            { id: "sales", title: "Sales" },
                          ].map((item) => ({
                            value: item.id,
                            label: item.title,
                          }))}
                        />
                      </Box>
                    </Stack>
                    {type.value !== "customer" && (
                      <Box>
                        <Stack>
                          <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                            Transaction No
                          </Text>
                          <Box>
                            <Input
                              onChange={(e) => setnoTransaction(e.target.value)}
                              fontFamily={"Regular"}
                              id="username"
                              placeholder="Search Transaction Nos"
                            />
                          </Box>
                        </Stack>
                      </Box>
                    )}
                    <Stack>
                      <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                        Start Date
                      </Text>

                      <HStack borderWidth={1} p={2} borderRadius={5}>
                        <ReactDatePicker
                          dateFormat="dd-MM-yyyy"
                          selected={filter.startDate}
                          onChange={(date) =>
                            setfilter((prevState) => ({
                              ...prevState,
                              startDate: new Date(date),
                            }))
                          }
                        />
                        <Calendar color="black" />
                      </HStack>
                    </Stack>
                    <Stack>
                      <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                        End Date
                      </Text>

                      <HStack borderWidth={1} p={2} borderRadius={5}>
                        <ReactDatePicker
                          dateFormat="dd-MM-yyyy"
                          selected={filter.endDate}
                          onChange={(date) =>
                            setfilter((prevState) => ({
                              ...prevState,
                              endDate: new Date(date),
                            }))
                          }
                        />
                        <Calendar color="black" />
                      </HStack>
                    </Stack>
                    <Box>
                      <Stack>
                        <Text fontFamily={"SemiBold"} fontSize={FONTSIZES.md}>
                          Store
                        </Text>
                        <Box>
                          <AsyncSelect
                            isClearable
                            value={selectedStore}
                            getOptionLabel={(e) => e.name}
                            getOptionValue={(e) => e.id}
                            onChange={(e) => setselectedStore(e)}
                            cacheOptions={false}
                            loadOptions={loadOptionsStore}
                            defaultOptions={false}
                          />
                        </Box>
                      </Stack>
                    </Box>
                  </Stack>
                </DrawerBody>
                <DrawerFooter borderTopWidth="1px">
                  <Button variant="outline" mr={3} onClick={onClose}>
                    <Text fontFamily={"Semibold"}>Cancel</Text>
                  </Button>
                  <Button colorScheme="teal" onClick={fetchData}>
                    <Text fontFamily={"Semibold"}>Submit</Text>
                  </Button>
                </DrawerFooter>
              </DrawerContent>
            </Drawer>
          </Stack>
        </WrapItem>

        {data.nodes?.length > 0 && (
          <WrapItem justifyContent={"flex-end"} flex={1}>
            <HStack>
              <Stack display={{ base: "flex" }}>
                <Text
                  fontSize={FONTSIZES.md}
                  color="white"
                  fontFamily={"SemiBold"}
                >
                  Period
                </Text>

                <Button w="30" colorScheme="teal" onClick={exportToExcel}>
                  <HStack>
                    <MyIcon name={"file-down"} size={18} color="white" />
                    <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                      Export
                    </Text>
                  </HStack>
                </Button>
              </Stack>
            </HStack>
          </WrapItem>
        )}
      </Wrap>
      <br />
      {type.value === "payment" ? (
        <Box p={1} borderWidth="1px" borderRadius="lg">
          {isLoading ? (
            <MyLoader />
          ) : data.nodes?.length > 0 ? (
            <Table
              layout={{ custom: true, horizontalScroll: true }}
              data={data}
              theme={theme}
            >
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          No
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Payment No
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Date
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Code
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Name
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Address
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Member
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Payment Method
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Notes
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Bill
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Payment
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Remaining
                        </Text>
                      </HeaderCell>
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item, index) => (
                      <Row key={item.id} item={item}>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {index + 1}
                          </Text>
                        </Cell>

                        <Cell>
                          <HStack>
                            <Text
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                              color="black"
                            >
                              {item.transaction_no}
                            </Text>
                          </HStack>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.payment_date}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.store.code}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.store.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.store.address}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.person?.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.payment_method?.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.notes}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp {formattedCurrency(item.total_bill)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp {formattedCurrency(item.total_pay)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp {formattedCurrency(item.total_remaining)}
                          </Text>
                        </Cell>
                      </Row>
                    ))}
                  </Body>
                  <Footer>
                    <FooterRow>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>

                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp{" "}
                          {formattedCurrency(
                            totalSales.sum_payments_total_bill
                          )}
                        </Text>
                      </FooterCell>

                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp{" "}
                          {formattedCurrency(totalSales.sum_payments_total_pay)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp{" "}
                          {formattedCurrency(
                            totalSales.sum_payments_total_remaining
                          )}
                        </Text>
                      </FooterCell>
                    </FooterRow>
                  </Footer>
                </>
              )}
            </Table>
          ) : (
            <Stack mt={5}>
              <Center>
                <Text fontFamily={"Semibold"}>Data Not Found</Text>
              </Center>
              <Lottie
                options={{
                  animationData: emptyLotie,
                }}
                width={"20vw"}
              />
            </Stack>
          )}
        </Box>
      ) : (
        <Box p={1} borderWidth="1px" borderRadius="lg">
          {isLoading ? (
            <MyLoader />
          ) : data.nodes?.length > 0 ? (
            <Table
              layout={{ custom: true, horizontalScroll: true }}
              data={data}
              theme={theme}
            >
              {(tableList) => (
                <>
                  <Header>
                    <HeaderRow>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          No
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Transaction No
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Date
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Payment Status
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Payment Method
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Member
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Code
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Name
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Store Address
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Item Count
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Point
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Shipping Cost
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Tax
                        </Text>
                      </HeaderCell>

                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Voucher
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Grand Total
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Total Payment
                        </Text>
                      </HeaderCell>
                      <HeaderCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Remaining
                        </Text>
                      </HeaderCell>
                    </HeaderRow>
                  </Header>

                  <Body>
                    {tableList.map((item, index) => (
                      <Row key={item.id} item={item}>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {index + 1}
                          </Text>
                        </Cell>

                        <Cell>
                          <HStack>
                            <Text
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                              color="black"
                            >
                              {item.transaction_no}
                            </Text>
                          </HStack>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.transaction_date}
                          </Text>
                        </Cell>
                        <Cell>
                          <HStack>
                            <Text
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                              color={
                                item.payment_status === "paid"
                                  ? "green"
                                  : item.payment_status === "partially"
                                  ? "orange"
                                  : "red"
                              }
                            >
                              {item.payment_status.toUpperCase()}
                            </Text>
                          </HStack>
                        </Cell>
                        <Cell>
                          <HStack>
                            <Text
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                              color="black"
                            >
                              {item.payment_method.name}
                            </Text>
                          </HStack>
                        </Cell>
                        <Cell>
                          <HStack>
                            <Text
                              fontSize={FONTSIZES.md}
                              fontFamily={"Regular"}
                              color="black"
                            >
                              {item.person?.name}
                            </Text>
                          </HStack>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.store.code}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.store.name}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.store.address}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {item.sales_details_count} Item
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.point)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            {formattedCurrency(item.total)}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.delivery_fee)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.tax)}
                          </Text>
                        </Cell>

                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.voucher)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.grand_total)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp.{" "}
                            {formattedCurrency(item.payment_details_sum_pay)}
                          </Text>
                        </Cell>
                        <Cell>
                          <Text
                            fontSize={FONTSIZES.md}
                            fontFamily={"Regular"}
                            color="black"
                          >
                            Rp. {formattedCurrency(item.difference)}
                          </Text>
                        </Cell>
                      </Row>
                    ))}
                  </Body>
                  <Footer>
                    <FooterRow>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        ></Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          {totalSales.sum_sales_point}
                        </Text>
                      </FooterCell>

                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp. {formattedCurrency(totalSales.sum_sales_total)}
                        </Text>
                      </FooterCell>

                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp.{" "}
                          {formattedCurrency(totalSales.sum_sales_delivery_fee)}
                        </Text>
                      </FooterCell>

                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp. {formattedCurrency(totalSales.sales_sum_tax)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp. {formattedCurrency(totalSales.sum_sales_voucher)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp.{" "}
                          {formattedCurrency(totalSales.sum_sales_grand_total)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp. {formattedCurrency(totalSales.sum_total_pay)}
                        </Text>
                      </FooterCell>
                      <FooterCell>
                        <Text
                          fontSize={FONTSIZES.md}
                          fontFamily={"Bold"}
                          color={"black"}
                        >
                          Rp.{" "}
                          {formattedCurrency(totalSales.sum_different_payment)}
                        </Text>
                      </FooterCell>
                    </FooterRow>
                  </Footer>
                </>
              )}
            </Table>
          ) : (
            <Stack mt={5}>
              <Center>
                <Text fontFamily={"Semibold"}>Data Not Found</Text>
              </Center>
              <Lottie
                options={{
                  animationData: emptyLotie,
                }}
                width={"20vw"}
              />
            </Stack>
          )}
        </Box>
      )}
      <br />
      {/* {data.nodes?.length > 0 && <PaginationNav />} */}

      <br />
    </Box>
  );
};

export default ReportPaymentBill;
