import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FONTSIZES } from "../../../theme/FontSize";
import { URL } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";

const RowVoucher = ({ item, index }) => {
  const { deleting } = useHttp();
  const navigate = useNavigate();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleDelete = () => {
    deleting({
      url: URL.getListVoucherURL + `/delete-row/${item.id}`,
      needAuth: true,
      showToast: true,
    }).then((res) => {
      onClose();
      navigate(-1);
    });
  };
  return (
    <>
      <HStack
        p="2"
        bg={index % 2 !== 0 ? "teal.50" : "grey.50"}
        flex={1}
        alignItems={"center"}
      >
        <Box flex={1} alignItems={"center"}>
          <Text
            textAlign="center"
            fontFamily={"Regular"}
            fontSize={FONTSIZES.md}
          >
            {item.code}
          </Text>
        </Box>

        <Box flex={1}>
          <Text
            textAlign="center"
            fontSize={FONTSIZES.md}
            fontFamily={"Regular"}
          >
            {item.person?.name}
          </Text>
        </Box>

        <Box flex={1}>
          <Text
            textAlign="center"
            fontSize={FONTSIZES.md}
            fontFamily={"Regular"}
          >
            {item.used === 1 ? "Used" : "Available"}
          </Text>
        </Box>
        <HStack justifyContent={"center"} flex={1}>
          <Button
            isDisabled={item.used === 1 ? true : false}
            colorScheme="red"
            onClick={onOpen}
          >
            <Text fontFamily="Semibold" fontSize={FONTSIZES.md}>
              Delete
            </Text>
          </Button>
        </HStack>
      </HStack>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
              Delete Voucher {item.code}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
              Are you sure?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant={"ghost"} mr={3} onClick={onClose}>
              <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                Close
              </Text>
            </Button>
            <Button colorScheme="red" onClick={handleDelete}>
              <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                Delete
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RowVoucher;
