import axios from "axios";
import { useSession } from "../utils/session";
import { apiKey } from "./appURL";
import { useToast } from "@chakra-ui/react";
import { useCallback } from "react";

export const useHttp = () => {
  const toast = useToast();
  const { removeSession, token } = useSession();

  const get = useCallback(
    ({ url, needAuth, showToast }) => {
      return new Promise((resolve, reject) => {
        if (needAuth && (token === null || !token)) {
          reject(
            "This operation need authentication but you're not authenticated"
          );
        } else {
          let header = {};
          if (needAuth) {
            header = {
              apikey: apiKey,
              Authorization: `Bearer ${token}`,
            };
          }
          axios
            .get(url, { headers: header })
            .then((response) => {
              if (showToast === true) {
                toast({
                  title: "Success",
                  description: response?.data?.message,
                  status: "success",
                  duration: 1000,
                  position: "top",
                });
              }
              resolve(response);
            })
            .catch((error) => {
              toast({
                title: error?.response?.data?.message,
                description: error?.response?.data?.message,
                status: "error",
                duration: 1000,
                position: "top",
              });
              reject(error);
              if (error.response?.status === 401) {
                removeSession({ doLogout: true });
              }
            });
        }
      });
    },
    [removeSession, toast, token]
  );

  const post = useCallback(
    ({ url, data, needAuth, showToast }) => {
      return new Promise((resolve, reject) => {
        if (needAuth && (token === null || !token)) {
          reject(
            "This operation need authentication but you're not authenticated"
          );
        } else {
          let header = {};
          if (needAuth) {
            header = {
              apikey: apiKey,
              Authorization: `Bearer ${token}`,
            };
          }
          axios
            .post(url, data, { headers: header })
            .then((response) => {
              if (showToast === true) {
                toast({
                  title: "Success",
                  description: response?.data?.message,
                  status: "success",
                  duration: 1000,
                  position: "top",
                });
              }
              resolve(response);
            })
            .catch((error) => {
              toast({
                title: error?.response?.data?.message,
                description: error?.response?.data?.message,
                status: "error",
                duration: 1000,
                position: "top",
              });
              reject(error);
              if (error.response?.status === 401) {
                removeSession({ doLogout: true });
              }
            });
        }
      });
    },
    [removeSession, toast, token]
  );

  const put = useCallback(
    ({ url, data, needAuth, showToast }) => {
      return new Promise((resolve, reject) => {
        if (needAuth && (token === null || !token)) {
          reject(
            "This operation need authentication but you're not authenticated"
          );
        } else {
          let header = {};
          if (needAuth) {
            header = {
              apikey: apiKey,
              Authorization: `Bearer ${token}`,
            };
          }
          axios
            .put(url, data, { headers: header })
            .then((response) => {
              if (showToast === true) {
                toast({
                  title: "Success",
                  description: response?.data?.message,
                  status: "success",
                  duration: 1000,
                  position: "top",
                });
              }
              resolve(response);
            })
            .catch((error) => {
              toast({
                title: error?.response?.data?.message,
                description: error?.response?.data?.message,
                status: "error",
                duration: 1000,
                position: "top",
              });
              reject(error);
              if (error.response?.status === 401) {
                removeSession({ doLogout: true });
              }
            });
        }
      });
    },
    [removeSession, toast, token]
  );

  const deleting = useCallback(
    ({ url, needAuth, showToast }) => {
      return new Promise((resolve, reject) => {
        if (needAuth && (token === null || !token)) {
          reject(
            "This operation need authentication but you're not authenticated"
          );
        } else {
          let header = {};
          if (needAuth) {
            header = {
              apikey: apiKey,
              Authorization: `Bearer ${token}`,
            };
          }
          axios
            .delete(url, { headers: header })
            .then((response) => {
              if (showToast === true) {
                toast({
                  title: "Success",
                  description: response?.data?.message,
                  status: "success",
                  duration: 1000,
                  position: "top",
                });
              }
              resolve(response);
            })
            .catch((error) => {
              toast({
                title: error?.response?.data?.message,
                description: error?.response?.data?.message,
                status: "error",
                duration: 1000,
                position: "top",
              });
              reject(error);
              if (error.response?.status === 401) {
                removeSession({ doLogout: true });
              }
            });
        }
      });
    },
    [removeSession, toast, token]
  );

  return { get, post, put, deleting };
};
