import { Box, Button, HStack, Stack, Text } from "@chakra-ui/react";

import React, { useState } from "react";
import { FONTSIZES } from "../../theme/FontSize";
import { formattedCurrency } from "../../utils/formatedCurrency";

const SalesLog = ({ item }) => {
  const [showMore, setshowMore] = useState(false);
  return (
    <HStack
      mb="5"
      flex={1}
      borderWidth={1}
      borderRadius={5}
      alignItems={"flex-start"}
    >
      <Stack flex={1} spacing={5} p={2} borderRadius={5}>
        <HStack alignItems={"flex-start"}>
          <Box w="40%">
            <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
              Transaction No
            </Text>
          </Box>
          <Box w="60%">
            <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
              {item.transaction_no}
            </Text>
          </Box>
        </HStack>
        <HStack alignItems={"flex-start"}>
          <Box w="40%">
            <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
              Date
            </Text>
          </Box>
          <Box w="60%">
            <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
              {item.created_at}
            </Text>
          </Box>
        </HStack>
        <HStack alignItems={"flex-start"}>
          <Box w="40%">
            <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
              Point
            </Text>
          </Box>
          <Box w="60%">
            <Text
              noOfLines={showMore === true ? 0 : 1}
              fontFamily={"Regular"}
              fontSize={FONTSIZES.md}
            >
              {item.point}
            </Text>
          </Box>
        </HStack>
        <HStack alignItems={"flex-start"}>
          <Box w="40%">
            <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
              Total
            </Text>
          </Box>
          <Box w="60%">
            <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
              Rp {formattedCurrency(item.total)}
            </Text>
          </Box>
        </HStack>
        <HStack alignItems={"flex-start"}>
          <Box w="40%">
            <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
              Payment
            </Text>
          </Box>
          <Box w="60%">
            <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
              {item.payment_method.name}
            </Text>
          </Box>
        </HStack>
      </Stack>
      <Stack flex={1} spacing={5} p={2} borderRadius={5}>
        <HStack alignItems={"flex-start"}>
          <Box w="40%">
            <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
              Voucher Code
            </Text>
          </Box>
          <Box w="60%">
            <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
              {item.voucher_code}
            </Text>
          </Box>
        </HStack>
        <HStack alignItems={"flex-start"}>
          <Box w="40%">
            <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
              Voucher
            </Text>
          </Box>
          <Box w="60%">
            <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
              Rp {formattedCurrency(item.voucher)}
            </Text>
          </Box>
        </HStack>
        <HStack alignItems={"flex-start"}>
          <Box w="40%">
            <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
              Tax
            </Text>
          </Box>
          <Box w="60%">
            <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
              Rp {formattedCurrency(item.tax)}
            </Text>
          </Box>
        </HStack>
        <HStack alignItems={"flex-start"}>
          <Box w="40%">
            <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
              Delivery
            </Text>
          </Box>
          <Box w="60%">
            <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
              Rp {formattedCurrency(item.delivery_fee)}
            </Text>
          </Box>
        </HStack>
        <HStack alignItems={"flex-start"}>
          <Box w="40%">
            <Text fontFamily={"Semibold"} fontSize={FONTSIZES.md}>
              Grand Total
            </Text>
          </Box>
          <Box w="60%">
            <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
              Rp {formattedCurrency(item.grand_total)}
            </Text>
          </Box>
        </HStack>
      </Stack>
    </HStack>
  );
};

export default SalesLog;
