import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { AsyncSelect, Select } from "chakra-react-select";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import NavButton from "../../../component/NavButton/Index";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import { baseURL, apiKey, URL } from "../../../utils/appURL";
import { chakraStyles } from "../../../utils/chakraStyle";
import Logo from "../../../assets/images/logo.png";
import MyIcon from "../../../component/MyIcon.js/Index";
import { FONTSIZES } from "../../../theme/FontSize";
import ActivityLogs from "../../../component/ActivityLog/Index";
import MyLoader from "../../../component/loader/MyLoader";
import { useHttp } from "../../../utils/http";
const DetailCashier = () => {
  const { get, put, deleting } = useHttp();
  const id = useParams();
  const [data, setdata] = useState({ activity_logs: [] });
  const navigate = useNavigate();
  const toast = useToast();
  const [password, setpassword] = useState("");
  const [store, setstore] = useState({ id: 0, name: "" });
  const [name, setname] = useState("");
  const [showLog, setshowLog] = useState(false);
  const [username, setusername] = useState("");
  const [type, settype] = useState();
  const [phone, setphone] = useState();
  const [datastore, setdatastore] = useState([]);
  const [dataRole, setdataRole] = useState([]);
  const [role, setrole] = useState({ id: 0, name: "" });
  const [address, setaddress] = useState("");
  const [code, setcode] = useState(0);
  const [isActive, setisActive] = useState(true);
  const [email, setemail] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const token = localStorage.getItem("token");
  const [isLoading, setisLoading] = useState(true);
  const [isDisable, setisDisable] = useState(true);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSubmit = () => {
    let params = {
      username: username,
      // password: password,
      store_id: store.id,
      role: role.name.toLowerCase(),
      name: name,
      code: code,

      email: email,
      phone_number: phone,
      address: address,
      is_active: isActive,
    };
    if (password.length > 7) {
      params = {
        username: username,
        password: password,
        store_id: store.id,
        role: role.name.toLowerCase(),
        name: name,
        code: code,

        email: email,
        phone_number: phone,
        address: address,
        is_active: isActive,
      };
    }
    put({
      url: URL.getListCashierURL + `/update/${id.id}`,
      data: params,
      needAuth: true,
      showToast: true,
    }).then((response) => {
      navigate(-1);
    });
  };
  const handleDelete = () => {
    deleting({
      url: URL.getListCashierURL + `/delete/${id.id}`,
      needAuth: true,
      showToast: true,
    }).then((response) => {
      onClose();
      navigate(-1);
    });
  };
  const clickEdit = () => {
    setisDisable(!isDisable);
  };
  const fetchDataStore = async (input) => {
    let allData = [];

    await get({
      url: URL.getListStoreURL + `?search=${input}&page=${1}`,
      needAuth: true,
      showToast: false,
    }).then((response) => {
      const responseData = response.data.data.data;

      allData = responseData;
    });
    return allData;
  };
  const loadOptionsStore = (inputValue) => {
    return fetchDataStore(inputValue);
  };

  const fetchData = () => {
    get({
      url: URL.getListCashierURL + `/${id.id}`,
      needAuth: true,
      showToast: false,
    }).then((response) => {
      setcode(response.data.data.code);
      setdata(response.data.data);
      setisActive(response.data.data.is_active);
      setname(response.data.data.name);
      setusername(response.data.data.person_access.username);
      setaddress(
        response.data.data.address === null ? "" : response.data.data.address
      );
      setstore(response.data.data.store);
      setrole(response.data.data.person_access.roles[0]);
      setisLoading(false);
    });
  };
  const fetchRole = () => {
    axios
      .get(
        `${baseURL}/settings/roles`,

        {
          headers: {
            apikey: apiKey,
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setdataRole(res.data.data.data);
      })
      .catch((err) => {
        const status = err.response?.status;
        if (status == 401) {
          navigate("/");
        }
        toast({
          title: err.response?.data.message,
          description: JSON.stringify(err.response?.data.errors),
          status: "error",
          duration: 2000,
          position: "top",
        });
      });
  };
  useEffect(() => {
    fetchData();
    fetchRole();
  }, []);

  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Cashier
            </Text>
          </Box>
          <HStack>
            <Button colorScheme="teal" onClick={() => setshowLog(true)}>
              <HStack>
                <MyIcon name={"notepad-text"} size={14} />
                <Text
                  fontSize={FONTSIZES.md}
                  fontFamily={"SemiBold"}
                  color="white"
                >
                  Show Log
                </Text>
              </HStack>
            </Button>
            <Button
              colorScheme="blue"
              minW={"100px"}
              bg={isDisable ? "blue.500" : "gray.500"}
              onClick={clickEdit}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={[10, 12, 14]}
                >
                  {isDisable ? "Edit" : "Cancel"}
                </Text>
              </HStack>
            </Button>
            <Button
              colorScheme="red"
              minW={"100px"}
              bg={"red.500"}
              onClick={onOpen}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={[10, 12, 14]}
                >
                  Delete
                </Text>
              </HStack>
            </Button>
          </HStack>
        </HStack>
        {isLoading ? (
          <MyLoader />
        ) : (
          <Box borderWidth={1} borderRadius={5} p={5}>
            <Text fontFamily={"Semibold"}>Detail Cashier</Text>
            <HStack flex={1}>
              <Stack flex={3} py={4} spacing={5}>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Name</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Input
                      isDisabled={isDisable}
                      value={name}
                      onChange={(e) => setname(e.target.value)}
                      fontFamily={"Regular"}
                    />
                    {name.length < 4 && (
                      <Box flex={0.2}>
                        <Text
                          fontSize={12}
                          fontFamily={"Regular"}
                          color="red.500"
                        >
                          Minimum 4 character !
                        </Text>
                      </Box>
                    )}
                  </Box>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Address</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Input
                      isDisabled={isDisable}
                      value={address}
                      onChange={(e) => setaddress(e.target.value)}
                      fontFamily={"Regular"}
                    />
                    {address.length < 4 && (
                      <Box flex={0.2}>
                        <Text
                          fontSize={12}
                          fontFamily={"Regular"}
                          color="red.500"
                        >
                          Minimum 4 character !
                        </Text>
                      </Box>
                    )}
                  </Box>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Store</Text>
                  </Box>
                  <Box flex={0.7}>
                    <AsyncSelect
                      isDisabled={isDisable}
                      value={store}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.id}
                      onChange={(e) => setstore(e)}
                      cacheOptions={false}
                      loadOptions={loadOptionsStore}
                      defaultOptions={false}
                    />
                  </Box>
                </HStack>

                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Role</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Select
                      isDisabled={isDisable}
                      value={{
                        value: role.id,
                        label: role.name,
                      }}
                      onChange={(e) => setrole({ id: e.value, name: e.label })}
                      w={500}
                      tagVariant="solid"
                      options={dataRole.map((item) => ({
                        value: item.id,
                        label: item.name,
                      }))}
                    />
                  </Box>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Username</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Input isDisabled value={username} fontFamily={"Regular"} />
                    {username.length < 4 && (
                      <Box flex={0.2}>
                        <Text
                          fontSize={12}
                          fontFamily={"Regular"}
                          color="red.500"
                        >
                          Minimum 4 character !
                        </Text>
                      </Box>
                    )}
                  </Box>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Password</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Input
                      placeholder="Minimum 8 character"
                      isDisabled={isDisable}
                      value={password}
                      onChange={(e) => setpassword(e.target.value)}
                      fontFamily={"Regular"}
                    />
                    {password.length > 0 && password.length < 8 && (
                      <Box flex={0.2}>
                        <Text
                          fontSize={12}
                          fontFamily={"Regular"}
                          color="red.500"
                        >
                          Minimum 8 character !
                        </Text>
                      </Box>
                    )}
                  </Box>
                </HStack>

                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Code</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Input
                      value={code}
                      readOnly
                      disabled
                      fontFamily={"Regular"}
                    />
                  </Box>
                </HStack>

                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Active</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Switch
                      isDisabled={isDisable}
                      onChange={() => setisActive(!isActive)}
                      isChecked={isActive == 1 ? true : false}
                    />
                  </Box>
                </HStack>
              </Stack>
            </HStack>
          </Box>
        )}
        <HStack py={10} justifyContent={"flex-end"}>
          {!isDisable && (
            <Button
              isDisabled={
                (password.length > 0 && password.length < 8) ||
                username.length < 4 ||
                name.length < 4
              }
              onClick={handleSubmit}
              colorScheme="teal"
              minW={"100px"}
              bg={"teal.500"}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={[10, 12, 14]}
                >
                  Save
                </Text>
              </HStack>
            </Button>
          )}
        </HStack>
      </Box>
      <Modal
        isOpen={showLog}
        onClose={() => setshowLog(false)}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={[12, 14, 16]}>
              Data log customer : {data.transaction_no}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {data.activity_logs.map((item, index) => {
              return <ActivityLogs item={item} key={index} index={index} />;
            })}
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={["xs", "sm", "md", "lg", "xl"]}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
              Delete Mitra {name}
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
              Are you sure?
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button variant={"ghost"} mr={3} onClick={onClose}>
              <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                Close
              </Text>
            </Button>
            <Button colorScheme="red" onClick={handleDelete}>
              <Text fontFamily={"Regular"} fontSize={FONTSIZES.md}>
                Delete
              </Text>
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AnimatedContainer>
  );
};
export default DetailCashier;
