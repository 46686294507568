import { Box, HStack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import PurchaseTable from "./PurchaseTable";

const Purchase = () => {
  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={"10"}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Purchase
            </Text>
          </Box>
          {/* <NavButton nav="/purchase/add" title="Add Purchase" /> */}
        </HStack>

        <PurchaseTable />
      </Box>
    </AnimatedContainer>
  );
};

export default Purchase;
