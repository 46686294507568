import { Box, Button, Center, Text } from "@chakra-ui/react";
import React from "react";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import lottiFiles from "../../assets/lotties/404.json";
import AnimatedContainer from "../../component/animatedContainer/AnimatedContainer";
const NotFound = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/");
  };
  return (
    <AnimatedContainer>
      <Box alignItems={"center"} justifyContent={"center"}>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: lottiFiles,
          }}
          height={"70vh"}
          width={"70vw"}
        />
        <Center alignItems={"center"} justifyContent={"center"}>
          <Button
            onClick={() => handleClick()}
            colorScheme="teal"
            bg="teal.400"
          >
            <Text fontFamily={"Semibold"}>Go To Page</Text>
          </Button>
        </Center>
      </Box>
    </AnimatedContainer>
  );
};

export default NotFound;
