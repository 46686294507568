import {
  Box,
  Button,
  HStack,
  Input,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import { AsyncSelect } from "chakra-react-select";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import { URL } from "../../../utils/appURL";
import { useHttp } from "../../../utils/http";

const AddCashier = () => {
  const { get, post } = useHttp();
  const navigate = useNavigate();
  const [store, setstore] = useState(0);
  const [name, setname] = useState("");
  const [username, setusername] = useState("");
  const [address, setaddress] = useState("");
  const [password, setpassword] = useState("");

  const [role, setrole] = useState({ id: 3, name: "cashier" });
  const [isActive, setisActive] = useState(true);
  const [currentPage, setcurrentPage] = useState(1);

  const handleSubmit = () => {
    const params = {
      name: name,
      address: address,
      store_id: store.id,
      is_active: isActive,
      username: username,
      password: password,
      role: role.name,
    };
    post({
      url: URL.getListCashierURL + "/store",
      data: params,
      needAuth: true,
      showToast: true,
    })
      .then((res) => {
        navigate(-1);
      })
      .catch((err) => {});
  };

  const fetchDataStore = async (input) => {
    let allData = [];
    await get({
      url: URL.getListStoreURL + `?search=${input}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((response) => {
      const newData = response?.data?.data?.data;
      allData.push(...newData);
    });
    console.log(allData);

    return allData;
  };
  const loadOptionsStore = (inputValue) => {
    return fetchDataStore(inputValue);
  };

  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Cashier
            </Text>
          </Box>
        </HStack>
        <Box borderWidth={1} borderRadius={5} p={5}>
          <Text fontFamily={"Semibold"}>Detail Cashier</Text>
          <HStack flex={1}>
            <Stack flex={3} py={4} spacing={5}>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text fontFamily={"Regular"}>Name</Text>
                </Box>
                <Box flex={0.7}>
                  <Input
                    onChange={(e) => setname(e.target.value)}
                    fontFamily={"Regular"}
                  />
                  {name.length < 4 && (
                    <Box flex={0.2}>
                      <Text
                        fontSize={12}
                        fontFamily={"Regular"}
                        color="red.500"
                      >
                        Minimum 4 character !
                      </Text>
                    </Box>
                  )}
                </Box>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text fontFamily={"Regular"}>Address</Text>
                </Box>
                <Box flex={0.7}>
                  <Input
                    onChange={(e) => setaddress(e.target.value)}
                    fontFamily={"Regular"}
                  />
                  {address.length < 4 && (
                    <Box flex={0.2}>
                      <Text
                        fontSize={12}
                        fontFamily={"Regular"}
                        color="red.500"
                      >
                        Minimum 4 character !
                      </Text>
                    </Box>
                  )}
                </Box>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text>Store</Text>
                </Box>
                <Box flex={0.7}>
                  <AsyncSelect
                    value={store}
                    getOptionLabel={(e) => e.name}
                    getOptionValue={(e) => e.id}
                    onChange={(e) => setstore(e)}
                    cacheOptions={false}
                    loadOptions={loadOptionsStore}
                    defaultOptions={false}
                  />
                </Box>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text fontFamily={"Regular"}>Username</Text>
                </Box>
                <Box flex={0.7}>
                  <Input
                    onChange={(e) => setusername(e.target.value)}
                    fontFamily={"Regular"}
                  />
                  {username.length < 4 && (
                    <Box flex={0.2}>
                      <Text
                        fontSize={12}
                        fontFamily={"Regular"}
                        color="red.500"
                      >
                        Minimum 4 character !
                      </Text>
                    </Box>
                  )}
                </Box>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text fontFamily={"Regular"}>Password</Text>
                </Box>
                <Box flex={0.7}>
                  <Input
                    onChange={(e) => setpassword(e.target.value)}
                    fontFamily={"Regular"}
                  />
                  {password.length < 8 && (
                    <Box flex={0.2}>
                      <Text
                        fontSize={12}
                        fontFamily={"Regular"}
                        color="red.500"
                      >
                        Minimum 8 character !
                      </Text>
                    </Box>
                  )}
                </Box>
              </HStack>
              <HStack flex={1} spacing={10} alignItems={"center"}>
                <Box flex={0.2}>
                  <Text fontFamily={"Regular"}>Active</Text>
                </Box>
                <Box flex={0.7}>
                  <Switch
                    onChange={() => setisActive(!isActive)}
                    isChecked={isActive == 1 ? true : false}
                  />
                </Box>
              </HStack>
            </Stack>
          </HStack>
        </Box>

        <HStack py={10} justifyContent={"flex-end"}>
          <Button
            isDisabled={
              password.length < 8 ||
              username.length < 4 ||
              name.length < 4 ||
              address.length < 4
            }
            onClick={handleSubmit}
            colorScheme="teal"
            minW={"100px"}
            bg={"teal.500"}
          >
            <HStack alignItems={"center"}>
              <Text
                fontFamily={"Semibold"}
                color="white"
                fontSize={[10, 12, 14]}
              >
                Save
              </Text>
            </HStack>
          </Button>
        </HStack>
      </Box>
    </AnimatedContainer>
  );
};
export default AddCashier;
