import { Box, HStack, Input, Stack, Text } from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import React from "react";
import NavButton from "../../../component/NavButton/Index";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import { chakraStyles } from "../../../utils/chakraStyle";

const AddPurchase = () => {
  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={10}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Purchase
            </Text>
          </Box>
          <NavButton nav={-1} title="Back" />
        </HStack>
        <Box borderWidth={1} borderRadius={5} p={5}>
          <Text fontFamily={"Semibold"}>Add Purchase</Text>
          <Stack spacing={8} maxW={"lg"} py={4}>
            <HStack spacing={4} alignItems={"center"}>
              <Box flex={0.2}>
                <Text>Input</Text>
              </Box>
              <Box flex={1}>
                <Input fontFamily={"Medium"} />
              </Box>
            </HStack>
            <HStack spacing={4} alignItems={"center"}>
              <Box flex={0.2}>
                <Text fontFamily={"Medium"}>Select</Text>
              </Box>
              <Box flex={1}>
                <Select
                  selectedOptionColorScheme="teal"
                  tagVariant="solid"
                  chakraStyles={chakraStyles}
                  options={[
                    {
                      label: "I have the outline style",
                      value: "i-am-outlined",
                      variant: "outline", // The option variant overrides the global
                      fontFamily: "Medium",
                    },
                    {
                      label: "I fallback to the global `solid`",
                      value: "i-am-solid",
                      fontFamily: "Medium",
                    },
                  ]}
                />
              </Box>
            </HStack>
          </Stack>
        </Box>
        <Box mt={4} borderWidth={1} borderRadius={5} p={5}>
          <Text fontFamily={"Semibold"}>Sub Add Purchase</Text>
          <Stack spacing={4} py={4}>
            <HStack spacing={4} alignItems={"center"}>
              <Box flex={1}>
                <Text>Label</Text>
              </Box>
              <Box flex={1}>
                <Text>Label</Text>
              </Box>
              <Box flex={1}>
                <Text>Label</Text>
              </Box>
            </HStack>
            <HStack spacing={4} alignItems={"center"}>
              <Box flex={1}>
                <Input fontFamily={"Medium"} />
              </Box>
              <Box flex={1}>
                <Input fontFamily={"Medium"} />
              </Box>
              <Box flex={1}>
                <Input fontFamily={"Medium"} />
              </Box>
            </HStack>
          </Stack>
        </Box>
      </Box>
    </AnimatedContainer>
  );
};
export default AddPurchase;
