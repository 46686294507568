import React from "react";

const ICFilter = (props) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 10V22M10 10H14"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12 2V7"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19 17V22"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5 15V22"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M19 14L19 2M21 14L17 14"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M5 12L5 2M7 12L3 12"
        stroke={props.color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ICFilter;
