import {
  Box,
  Button,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from "@chakra-ui/react";
import axios from "axios";
import { AsyncSelect } from "chakra-react-select";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useNavigate } from "react-router-dom";
import Empty from "../../../assets/lotties/empty.json";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";
import MyLoader from "../../../component/loader/MyLoader";
import { FONTSIZES } from "../../../theme/FontSize";
import { apiKey, baseURL, URL } from "../../../utils/appURL";
import { formattedCurrency } from "../../../utils/formatedCurrency";
import { useHttp } from "../../../utils/http";
const AddPaymentBill = () => {
  const { post, get } = useHttp();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fileName, setfileName] = useState("");
  const navigate = useNavigate();
  const [dataSales, setdataSales] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [totalPage, settotalPage] = useState(1);
  const [nextUrl, setnextUrl] = useState(`${baseURL}/master/products?page=1`);
  const [dataProduct, setdataProduct] = useState([]);
  const [selectedProduct, setselectedProduct] = useState({ id: 0 });
  const [dataCustomer, setdataCustomer] = useState([]);
  const [customer, setcustomer] = useState({ id: null });
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(new Date());
  const toast = useToast();
  const [masterDataProduct, setmasterDataProduct] = useState([]);
  const [total_voucher, settotal_voucher] = useState(0);
  const [dataCategory, setdataCategory] = useState([]);
  const [selectedCategory, setselectedCategory] = useState({ id: 0 });
  const [dataSubCategory, setdataSubCategory] = useState([]);
  const [selectedSubCategory, setselectedSubCategory] = useState({ id: 0 });
  const [store, setstore] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [name, setname] = useState();
  const [nominal, setnominal] = useState();
  const [notes, setnotes] = useState();
  const [dataRole, setdataRole] = useState([]);
  const [datastore, setdatastore] = useState([]);
  const [role, setrole] = useState([]);
  const [isActive, setisActive] = useState(true);
  const token = localStorage.getItem("token");
  const [search, setsearch] = useState("");
  // const [dataApplies, setdataApplies] = useState([
  //   { title: "All", value: "all" },
  //   { title: "Product", value: "product" },
  //   { title: "Category", value: "category" },
  //   { title: "Sub Category", value: "subcategory" },
  // ]);
  const [dataApplies, setdataApplies] = useState([
    { title: "All", value: "all" },
    { title: "Member", value: "member" },
  ]);
  const [dataPayment, setdataPayment] = useState([]);
  const [paymentMethod, setpaymentMethod] = useState(null);
  const [dataMember, setdataMember] = useState([]);
  const [member, setmember] = useState(null);
  const [selectedApplies, setselectedApplies] = useState("all");
  const [generateDisable, setGenerateDisable] = useState(true);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedVoucherIndex, setSelectedVoucherIndex] = useState(null);
  const [totalPayment, settotalPayment] = useState("0");
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleSubmit = () => {
    setisLoading(true);
    const newData = dataSales
      .filter((item) => item?.pay !== 0)
      .map((item) => ({
        payment_detail_id: null,
        sales_header_id: item.id,
        bill: item.remaining,
        pay: item?.pay?.toString().replace(/\,/g, "") || 0,
        notes: item.notes || "",
      }));

    let params = {
      store_id: store?.id,
      person_id: member?.id,
      payment_method_id: paymentMethod?.id,
      payment_date: new Date(),
      notes: notes,
      details: newData,
    };
    if (store.id !== null) {
      params = { ...params, store_id: store.id };
    }

    post({
      url: URL.getPaymentBillURL + "/store",
      data: params,
      needAuth: true,
      showToast: true,
    }).then((res) => {
      console.log(res);
      navigate(-1);
    });
  };
  const fetchDataSales = () => {
    const totalPaymentWithoutThousandSeparator = totalPayment.replace(/,/g, "");
    get({
      url:
        URL.getPaymentBillURL +
        `/sales/list?store_id=${store?.id}&person_id=${member?.id}&total_payment=${totalPaymentWithoutThousandSeparator}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      const newData = res.data.data.data.map((item, index) => ({
        ...item,
        payment: "",
      }));
      setdataSales(newData);
    });
  };

  const fetchDataPayment = async (input = "") => {
    let allData = [];
    let currentPage = 1;
    await get({
      url:
        URL.getListPaymentMethod +
        `?search=${input}&page=${currentPage}&with_paylater=0`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      const responseData = res.data.data.data;
      allData = responseData;
    });
    return allData;
  };
  const fetchDataStore = async (input) => {
    let allData = [];
    let currentPage = 1;
    await get({
      url: URL.getListStoreURL + `?search=${input}&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      setisLoading(false);
      allData = res.data.data.data;
    });
    return allData;
  };
  const fetchDataMember = async (input = "") => {
    let allData = [];
    let currentPage = 1;
    await get({
      url:
        URL.getListCustomerURL +
        `?search=${input}&store_id=${store.id}&just_active=1&page=${currentPage}`,
      needAuth: true,
      showToast: false,
    }).then((res) => {
      const responseData = res.data.data.data;
      allData = responseData;
    });
    setdataMember(allData);
    return allData;
  };

  const loadOptionsStore = (inputValue) => {
    return fetchDataStore(inputValue);
  };
  const loadOptionsMember = (inputValue) => {
    return fetchDataMember(inputValue);
  };
  const loadOptionsPaymentMethod = (inputValue) => {
    return fetchDataPayment(inputValue);
  };

  useEffect(() => {
    fetchDataPayment();
  }, []);
  useEffect(() => {
    setdataMember([]);
    setmember(null);
    setdataSales([]);
    if (store) {
      fetchDataMember();
    }
  }, [store]);

  useEffect(() => {
    setdataSales([]);
  }, [member]);

  useEffect(() => {
    const total = dataSales?.reduce((total, item) => {
      if (typeof item?.pay === "number") {
        return total + item?.pay;
      }
      const value = Number(item?.pay?.replace(/,/g, ""));
      return total + value;
    }, 0);
    const s = total?.toLocaleString("en-US", { maximumFractionDigits: 0 });
    settotalPayment(s);
  }, [dataSales]);

  return (
    <AnimatedContainer>
      {isLoading ? (
        <Box position={"absolute"}>
          <MyLoader />
        </Box>
      ) : (
        <Box flex={1} bg="white" p="4" rounded={10}>
          <HStack justifyContent={"space-between"} mb={5}>
            <Box>
              <Text fontFamily={"Semibold"} color="black">
                Payment Bill
              </Text>
            </Box>
            <HStack justifyContent={"space-between"}>
              <Button
                colorScheme="red"
                onClick={() => {
                  setmember(null);
                  setstore(null);
                  setdataMember([]);
                  setdataSales([]);
                  setfileName("");
                }}
              >
                <Text fontFamily={"Regular"} fontSize={12}>
                  Clear All
                </Text>
              </Button>
            </HStack>
          </HStack>
          <Box borderWidth={1} borderRadius={5} p={5}>
            {/* <Text fontFamily={"Semibold"}>Detail payment</Text> */}
            <HStack flex={1}>
              <Stack flex={3} py={4} spacing={5}>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Store</Text>
                  </Box>
                  <Box flex={0.7}>
                    <AsyncSelect
                      isDisabled={fileName.length > 0}
                      value={store}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.id}
                      onChange={(e) => {
                        setstore(e);
                      }}
                      cacheOptions={false}
                      loadOptions={loadOptionsStore}
                      defaultOptions={false}
                    />
                  </Box>
                </HStack>

                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Member</Text>
                  </Box>
                  <Box flex={0.7}>
                    {dataMember.length > 0 ? (
                      <AsyncSelect
                        isDisabled={fileName.length > 0}
                        value={member}
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.id}
                        onChange={(e) => setmember(e)}
                        cacheOptions={false}
                        loadOptions={loadOptionsMember}
                        defaultOptions={false}
                      />
                    ) : (
                      <Input
                        color={"red.500"}
                        isDisabled={true}
                        value={"Please Select Store First!"}
                        onChange={(e) => setnotes(e.target.value)}
                        fontFamily={"Regular"}
                      />
                    )}
                  </Box>
                </HStack>

                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Payment Method</Text>
                  </Box>
                  <Box flex={0.7}>
                    <AsyncSelect
                      isDisabled={fileName.length > 0}
                      value={paymentMethod}
                      getOptionLabel={(e) => e.name}
                      getOptionValue={(e) => e.id}
                      onChange={(e) => setpaymentMethod(e)}
                      cacheOptions={false}
                      loadOptions={loadOptionsPaymentMethod}
                      defaultOptions={false}
                    />
                  </Box>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Payment</Text>
                  </Box>
                  <Box flex={0.7}>
                    {/* <Input
                      isDisabled={fileName.length > 0}
                      value={totalPayment}
                      onChange={(e) => settotalPayment(e.target.value)}
                      fontFamily={"Regular"}
                    /> */}
                    <InputGroup fontSize={12} flex={1}>
                      <InputLeftAddon>
                        <Text fontFamily={"Semibold"} fontSize={16}>
                          Rp.
                        </Text>
                      </InputLeftAddon>
                      <NumberInput
                        borderRadius={0}
                        rounded={0}
                        sx={{
                          input: {
                            bg: "white",
                            borderLeftRadius: 0,
                            fontSize: 16,
                            fontFamily: "Regular",
                          },
                        }}
                        isDisabled={fileName.length > 0}
                        flex={1}
                        value={totalPayment || 0}
                        min={1}
                        // max={item.remaining || 0}
                        onChange={(v) => {
                          settotalPayment(
                            v
                              .replace(/^0+/, "")
                              .replace(/^0+(?=\d)/, "")
                              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                          );
                        }}
                      >
                        <NumberInputField />
                      </NumberInput>
                    </InputGroup>
                  </Box>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Box flex={0.2}>
                    <Text>Notes</Text>
                  </Box>
                  <Box flex={0.7}>
                    <Input
                      isDisabled={fileName.length > 0}
                      value={notes}
                      onChange={(e) => setnotes(e.target.value)}
                      fontFamily={"Regular"}
                    />
                  </Box>
                </HStack>
                <HStack flex={1} spacing={10} alignItems={"center"}>
                  <Button
                    isDisabled={
                      totalPayment < 1 || member == null || store == null
                    }
                    colorScheme="teal"
                    onClick={fetchDataSales}
                  >
                    <Text fontFamily={"Semibold"} fontSize={12}>
                      Submit
                    </Text>
                  </Button>
                </HStack>
              </Stack>
            </HStack>
          </Box>

          <HStack mt="5" borderTopRadius={5} p="2" bg="teal.500" w="100%">
            <HStack justifyContent="center" flex={1}>
              <Text
                fontSize={FONTSIZES.md}
                textAlign={"center"}
                color="white"
                fontFamily={"Semibold"}
              >
                No Sales
              </Text>
            </HStack>
            <HStack justifyContent="center" flex={1}>
              <Text
                fontSize={FONTSIZES.md}
                textAlign={"center"}
                color="white"
                fontFamily={"Semibold"}
              >
                Status Payment
              </Text>
            </HStack>
            {/* <HStack justifyContent="center" flex={1}>
              <Text
                fontSize={FONTSIZES.md}
                textAlign={"center"}
                color="white"
                fontFamily={"Semibold"}
              >
                Bill
              </Text>
            </HStack> */}
            <HStack justifyContent="center" flex={1}>
              <Text
                fontSize={FONTSIZES.md}
                textAlign={"center"}
                color="white"
                fontFamily={"Semibold"}
              >
                Bill
              </Text>
            </HStack>
            <HStack justifyContent="center" flex={1}>
              <Text
                fontSize={FONTSIZES.md}
                textAlign={"center"}
                color="white"
                fontFamily={"Semibold"}
              >
                Payment
              </Text>
            </HStack>
            <HStack justifyContent="center" flex={1}>
              <Text
                fontSize={FONTSIZES.md}
                textAlign={"center"}
                color="white"
                fontFamily={"Semibold"}
              >
                Notes
              </Text>
            </HStack>
            {/* <HStack justifyContent="center" flex={0.2}>
              <Text
                textAlign={"center"}
                color="white"
                fontFamily={"Semibold"}
              ></Text>
            </HStack> */}
          </HStack>

          <Box borderBottomRadius={5} borderWidth={1} py={2}>
            {dataSales.map((item, index) => {
              return (
                <VStack
                  // bg={index % 2 === 0 ? "white" : "gray.50"}
                  alignItems={"flex-start"}
                  // borderBottomRadius={5}
                  px={5}
                  pt={4}
                  pb={2}
                  key={index}
                >
                  <HStack w={"100%"}>
                    <Box flex={1} alignItems={"center"}>
                      <Input
                        bg={"gray.50"}
                        readOnly
                        fontSize={12}
                        isDisabled={fileName.length > 0}
                        value={item.transaction_no}
                        fontFamily={"Regular"}
                      />
                    </Box>
                    <Box flex={1} alignItems={"center"}>
                      <Input
                        fontSize={12}
                        bg={
                          item.payment_status === "paid"
                            ? "teal.500"
                            : item.payment_status === "partial"
                            ? "orange.500"
                            : "red.500"
                        }
                        readOnly
                        color="white"
                        value={item.payment_status.toUpperCase()}
                        fontFamily={"Semibold"}
                      />
                    </Box>

                    <Box flex={1} alignItems={"center"}>
                      <InputGroup fontSize={12} flex={1}>
                        <InputLeftAddon>
                          <Text fontFamily={"Semibold"} fontSize={12}>
                            Rp.
                          </Text>
                        </InputLeftAddon>
                        <NumberInput
                          readOnly
                          borderRadius={0}
                          rounded={0}
                          sx={{
                            input: {
                              bg: "gray.50",
                              borderLeftRadius: 0,
                              fontSize: 12,
                              fontFamily: "Regular",
                            },
                          }}
                          isDisabled={fileName.length > 0}
                          flex={1}
                          value={formattedCurrency(item.remaining) || 0}
                          min={0}
                          max={item.remaining || 0}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </InputGroup>
                    </Box>
                    <Box flex={1} alignItems={"center"}>
                      <InputGroup fontSize={12} flex={1}>
                        <InputLeftAddon>
                          <Text fontFamily={"Semibold"} fontSize={12}>
                            Rp.
                          </Text>
                        </InputLeftAddon>
                        <NumberInput
                          borderRadius={0}
                          rounded={0}
                          sx={{
                            input: {
                              bg: "white",
                              borderLeftRadius: 0,
                              fontSize: 12,
                              fontFamily: "Regular",
                            },
                          }}
                          isDisabled={fileName.length > 0}
                          flex={1}
                          value={
                            typeof item.pay === "string"
                              ? item.pay
                                  .replace(/^0+/, "")
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                              : item.pay
                                  .toString()
                                  .replace(/^0+/, "")
                                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
                          }
                          min={0}
                          max={item.remaining || 0}
                          onChange={(v) => {
                            const newPayment = [...dataSales];
                            newPayment[index] = {
                              ...newPayment[index],
                              pay: v
                                .replace(/^0+/, "")
                                .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,"),
                            };
                            setdataSales(newPayment);
                          }}
                        >
                          <NumberInputField />
                        </NumberInput>
                      </InputGroup>
                      <Text position={"absolute"} color="red.500" fontSize={10}>
                        Max : {formattedCurrency(item?.remaining)}
                      </Text>
                    </Box>
                    <Box flex={1} alignItems={"center"}>
                      <Input
                        bg="white"
                        onChange={(v) => {
                          const newPayment = [...dataSales];
                          newPayment[index] = {
                            ...newPayment[index],
                            notes: v.target.value,
                          };
                          setdataSales(newPayment);
                        }}
                        value={item.notes}
                        fontFamily={"Regular"}
                      />
                    </Box>
                    {/* <Center flex={0.2} justifyContent={"center"}>
                      <Box>
                        <Button
                          isDisabled={
                            dataSales.length < 2 || fileName.length > 0
                          }
                          alignSelf={"center"}
                          onClick={() => handleDeleteRow(index)}
                          colorScheme="red"
                        >
                          <Icon as={CircleX} color={"white"} />
                        </Button>
                      </Box>
                    </Center> */}
                  </HStack>
                  <Text color="red.500" fontSize={12}>
                    {item?.messages}
                  </Text>
                </VStack>
              );
            })}
            {dataSales.length < 1 && (
              <Stack>
                <Lottie
                  options={{
                    animationData: Empty,
                  }}
                  width={"20vw"}
                />
                <Text textAlign={"center"} fontFamily={"Semibold"}>
                  No Sales Found
                </Text>
              </Stack>
            )}
          </Box>
          <HStack py={10} justifyContent={"flex-end"}>
            <Button
              isDisabled={
                dataSales.length < 1 ||
                dataSales.every((item) => item?.pay == 0) ||
                member === null ||
                paymentMethod === null
              }
              onClick={handleSubmit}
              colorScheme="teal"
              minW={"100px"}
              bg={"teal.500"}
            >
              <HStack alignItems={"center"}>
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={[10, 12, 14]}
                >
                  Save
                </Text>
              </HStack>
            </Button>
          </HStack>
        </Box>
      )}
    </AnimatedContainer>
  );
};
export default AddPaymentBill;
