import { Box, Button, HStack, Text } from "@chakra-ui/react";
import React from "react";
import AnimatedContainer from "../../../component/animatedContainer/AnimatedContainer";

import MyIcon from "../../../component/MyIcon.js/Index";
import CashierTable from "./CashierTable";

const Cashier = () => {
  return (
    <AnimatedContainer>
      <Box flex={1} bg="white" p="4" rounded={"10"}>
        <HStack justifyContent={"space-between"} mb={5}>
          <Box>
            <Text fontFamily={"Semibold"} color="black">
              Cashier
            </Text>
          </Box>
          <a href="#/cashier/add">
            <Button
              // onClick={() => navigate(`/cashier/add`)}
              colorScheme="teal"
              minW={"100px"}
              bg={"teal.500"}
            >
              <HStack alignItems={"center"}>
                <MyIcon name="plus" size="14" />
                <Text
                  fontFamily={"Semibold"}
                  color="white"
                  fontSize={[10, 12, 14]}
                >
                  Add New
                </Text>
              </HStack>
            </Button>
          </a>
        </HStack>

        <CashierTable />
      </Box>
    </AnimatedContainer>
  );
};

export default Cashier;
