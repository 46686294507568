import {
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useCallback, useState } from "react";

export const useSession = () => {
    const toast     = useToast();
    const navigate  = useNavigate();
    const [hasSession, sethasSession] = useState( false );
    const [token, settoken] = useState(localStorage.getItem("token"));

    const checkSession = useCallback(({ doLogout }) => {
        return new Promise((resolve, reject) => {
            if (token === null || !token) {
                sethasSession(false);
                if (doLogout === true) {
                    toast({
                        title: "Loged out!",
                        description: 'Session timed out',
                        status: "error",
                        duration: 1000,
                        position: "top",
                    });
                    navigate("/login");
                }
                reject('No Session');
            } else {
                sethasSession(true);
                resolve('Has Session');
            }
        });
    },[navigate, toast, token]);

    const removeSession = useCallback(({ doLogout }) => {
        return new Promise((resolve, reject) => {
            sethasSession(false);
            settoken("");
            localStorage.removeItem("token");
            if (doLogout === true) {
                toast({
                    title: "Loged out!",
                    description: 'Session timed out',
                    status: "error",
                    duration: 1000,
                    position: "top",
                });
                navigate("/login");
                resolve("Loged out");
                
            } else {
                resolve("Loged out");
            }
        });
    }, [navigate, toast]);
    return { checkSession, removeSession, hasSession, token };
}

